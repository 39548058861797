import React from 'react';
import { string, number, oneOf } from 'prop-types';
import { UTLabel } from '@widergy/energy-ui';

import styles from './styles.module.scss';

const RechargeItemDetail = ({ name, value }) => (
  <div key={name} className={styles.dataRow}>
    <UTLabel secondary className={styles.item}>
      {name}
    </UTLabel>
    <UTLabel className={styles.item}>{value}</UTLabel>
  </div>
);
RechargeItemDetail.propTypes = {
  name: string,
  value: oneOf([string, number])
};

export default RechargeItemDetail;
