import React, { Fragment } from 'react';
import { UTLabel, UTIconButton, UTTooltip } from '@widergy/energy-ui';
import { string, func } from 'prop-types';
import Edit from '@material-ui/icons/Edit';
import DeleteForever from '@material-ui/icons/DeleteForever';
import Add from '@material-ui/icons/Add';
import i18 from 'i18next';

import styles from './styles.module.scss';

const DigitalBill = ({ email, openAdd, openEdit, openRemove, message }) => (
  <div className={styles.withEmail}>
    <UTLabel large className={styles.email}>
      {email || message}
    </UTLabel>
    <div className={styles.iconContainer}>
      {email ? (
        <Fragment>
          <UTTooltip tippyProps={{ touch: ['hold', 800], zIndex: 10 }} content={i18.t('Commons:modify')}>
            <span>
              <UTIconButton onClick={openEdit}>
                <Edit />
              </UTIconButton>
            </span>
          </UTTooltip>
          <UTTooltip tippyProps={{ touch: ['hold', 800], zIndex: 10 }} content={i18.t('Commons:delete')}>
            <span>
              <UTIconButton onClick={openRemove}>
                <DeleteForever />
              </UTIconButton>
            </span>
          </UTTooltip>
        </Fragment>
      ) : (
        <UTTooltip tippyProps={{ touch: ['hold', 800], zIndex: 10 }} content={i18.t('Commons:add')}>
          <span>
            <UTIconButton onClick={openAdd}>
              <Add />
            </UTIconButton>
          </span>
        </UTTooltip>
      )}
    </div>
  </div>
);

DigitalBill.propTypes = {
  email: string,
  openAdd: func,
  openEdit: func,
  openRemove: func,
  message: string
};

export default DigitalBill;
