import React, { useEffect } from 'react';
import { bool } from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Error from '@material-ui/icons/Error';
import { UTLabel } from '@widergy/energy-ui';
import { objectIsEmpty } from '@widergy/web-utils/lib/object';

import UTTopbar from 'app/components/UTTopbar';
import UTFooter from 'app/components/UTFooter';
import { healthCheckType } from 'types/utilityTypes';
import { DEFAULT_ROUTES } from 'constants/routes';

import { getMessage } from './utils';
import styles from './styles.module.scss';

const Maintenance = ({ loading, healthCheck, dispatch, configEmpty }) => {
  useEffect(() => {
    if (loading && healthCheck.healthy !== false && !configEmpty)
      return dispatch(push(DEFAULT_ROUTES.RETRIEVE_SESSION));
  }, [dispatch, healthCheck.healthy, loading]);

  const message = getMessage(healthCheck.message);

  return (
    <div className={styles.container}>
      <UTTopbar />
      <div className={styles.maintenance}>
        <Error className={styles.icon} />
        <UTLabel bold xxlarge gray>
          {message}
        </UTLabel>
      </div>
      <UTFooter />
    </div>
  );
};

Maintenance.propTypes = {
  loading: bool,
  healthCheck: healthCheckType,
  configEmpty: bool
};

const mapStateToProps = store => ({
  loading: store.auth.sessionLoading,
  healthCheck: store.utility.healthCheck,
  configEmpty: objectIsEmpty(store.utility.configuration)
});

export default connect(mapStateToProps)(Maintenance);
