import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { bool, string } from 'prop-types';

import { ROUTE_TYPES, DEFAULT_ROUTES } from 'constants/routes';

const { PUBLIC, PRIVATE, RESTRICTED } = ROUTE_TYPES;

const RouteHandler = ({ session, sessionLoading, type, component: Comp, ...props }) => {
  const URL = props.location.pathname;

  return (
    <Route
      {...props}
      render={routeProps => {
        if (type === PUBLIC) return <Comp {...props} {...routeProps} />;
        if (sessionLoading && URL !== DEFAULT_ROUTES.RETRIEVE_SESSION)
          return <Redirect to={DEFAULT_ROUTES.RETRIEVE_SESSION} />;
        if (session && type === RESTRICTED) return <Redirect to={DEFAULT_ROUTES.PRIVATE} />;
        if (!session && type === PRIVATE) return <Redirect to={DEFAULT_ROUTES.RESTRICTED} />;
        return <Comp {...props} {...routeProps} />;
      }}
    />
  );
};

RouteHandler.propTypes = {
  ...Route.propTypes,
  sessionLoading: bool,
  session: bool,
  type: string
};

const mapStateToProps = store => ({
  sessionLoading: store.auth.sessionLoading,
  session: !store.auth.sessionLoading && !!store.user.currentUser
});

export default connect(mapStateToProps)(RouteHandler);
