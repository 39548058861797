import { any, string, arrayOf, shape, number, bool, oneOfType, func, instanceOf } from 'prop-types';

export const officeType = shape({
  id: number,
  address: string,
  city: string
});

export const officesType = arrayOf(officeType);

export const workingDaySlotType = shape({
  id: number,
  working_day_id: number,
  available: bool,
  total_turns: number,
  begins_at: string,
  ends_at: string
});

export const turnType = shape({
  id: number,
  user_id: number,
  user_firt_name: string,
  user_last_name: string,
  user_document_type: string,
  user_document_number: number,
  status: string,
  office_city: string,
  office_address: string,
  date: string,
  working_day_slot: workingDaySlotType
});

export const referenceType = oneOfType([func, shape({ current: any })]);

export const valuesType = shape({
  contactInformation: shape(any),
  selectedDate: instanceOf(Date),
  selectedOffice: number,
  selectedProcedure: string,
  selectedTime: number,
  resultDialogOpen: bool
});
