import { AlertHandler } from '@widergy/energy-ui';
import i18n from 'i18next';
import { normalizeByRegex } from '@widergy/web-utils/lib/normalize';

import { MANAGE_TURNS } from 'constants/routes';

export default {
  alertDialog: {
    dialogProps: {
      whiteTitle: true
    },
    textProps: {
      white: true
    }
  },
  dialogButton: {
    accept: {
      text: true
    },
    cancel: {
      text: true,
      gray: true
    }
  },
  dialogLabel: {
    primary: true
  },
  topbar: {
    link: '',
    withLogo: true,
    withLogout: true,
    withLabel: false,
    withLogOutCustomIcon: false
  },
  footer: {
    enabled: false
  },
  turns: {
    clientNumberRequired: false,
    createTurnsEnabled: true,
    withAssignment: true,
    withLastActivity: true
  },
  forms: {
    clientNumberLength: 7,
    nameMaxLength: 999,
    lastNameMaxLength: 999,
    phoneLength: 11,
    phoneFieldWithAreaCode: true,
    clientNumberNormalizer: normalizeByRegex(/^[0-9]{0,7}$/),
    clientNumberPatternValidation: /^[0-9]{7}$/,
    docTypes: {
      dni: true,
      cuit: true,
      provincialCi: true,
      identityCard: true,
      enlistmentBook: true,
      passport: true,
      civicNotebook: true
    }
  },
  buttonRadius: '0px',
  fontFamily: 'Helvetica Neue',
  defaultRoute: MANAGE_TURNS,
  contactInformation: {
    emailRequired: true
  },
  applicationRefresh: {
    url: `${window.origin}/static/js/`,
    forceReload: true,
    withAlertAction: true,
    alertDispatcher: () => AlertHandler.error(i18n.t('Commons:refreshing')),
    pollingTime: 3600000
  },
  offices: {
    officeTag: {
      withOfficeCity: true
    }
  },
  customerInfo: {
    nameInfo: {
      enabled: false
    }
  }
};
