import React from 'react';
import i18n from 'i18next';
import { connect } from 'react-redux';
import { any, bool, func, shape, string } from 'prop-types';
import { getIn } from 'seamless-immutable';
import { UTButton } from '@widergy/energy-ui';

import OfficeActions from 'redux/office/actions';

import styles from './styles.module.scss';

const SubmitForm = ({ dispatch, loading, openResultDialog, values, turnForms }) => {
  const handleCreateTurn = () => {
    const formSelected = turnForms.find(item => item.value === values.selectedProcedure) || {};
    dispatch(
      OfficeActions.createTurn(
        values.selectedTime,
        formSelected.label,
        values.contactInformation,
        values.selectedAttentionType,
        openResultDialog
      )
    );
  };

  return (
    <UTButton onPress={handleCreateTurn} className={styles.submitButton} loading={loading}>
      {i18n.t('Turns:confirmTurnButton')}
    </UTButton>
  );
};

SubmitForm.propTypes = {
  loading: bool,
  openResultDialog: func,
  values: shape(any),
  turnForms: shape({
    form_ov: bool,
    label: string,
    value: string
  })
};

const mapStateToProps = store => ({
  loading: store.office.createTurnLoading,
  turnForms: getIn(store, ['utility', 'configuration', 'turn_forms'], [])
});

export default connect(mapStateToProps)(SubmitForm);
