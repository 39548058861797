export const handleScrollTo = ref =>
  ref.current && window.scrollTo({ top: ref.current.offsetTop, behavior: 'smooth' });

export const getAttentionTypes = (selectedOffice, offices, utilityAttentionTypes = []) =>
  selectedOffice
    ? offices
        .find(office => office.id === selectedOffice)
        .attention_types?.filter(attType =>
          utilityAttentionTypes.find(utilityAttType => utilityAttType === attType)
        )
    : [];
