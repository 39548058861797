import { isEmpty } from '@widergy/web-utils/lib/array';

export const filterOptions = (options, filter) => {
  if (isEmpty(options)) return [];
  return options.filter(option => {
    const { name, description } = option;
    const validationArray = [name, description];
    return validationArray.some(el => el != null && el.toLowerCase().includes(filter.toLowerCase()));
  });
};
