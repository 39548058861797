import React from 'react';
import i18 from 'i18next';
import { string, func, shape } from 'prop-types';
import { UTCard, UTLabel, UTButton } from '@widergy/energy-ui';

import styles from './styles.module.scss';

const CardError = ({ title, error, textButton, buttonFunction, classes = {} }) => (
  <UTCard flat className={`${styles.containerCard} ${classes.cardClassName}`}>
    <div className={styles.cardContent}>
      <UTLabel darkGray large extrabold className={`${styles.label} ${classes.titleClassName}`}>
        {title || i18.t('Turns:titleErrorCard')}
      </UTLabel>
      {error && (
        <UTLabel darkGray className={`${styles.label} ${classes.labelClassName}`}>
          {error}
        </UTLabel>
      )}
      {buttonFunction && (
        <UTButton className={`${styles.button} ${classes.buttonClassName}`} outlined onPress={buttonFunction}>
          {textButton || i18.t('Turns:buttonErrorCard')}
        </UTButton>
      )}
    </div>
  </UTCard>
);

CardError.propTypes = {
  title: string,
  error: string,
  textButton: string,
  buttonFunction: func,
  classes: shape({
    cardClassName: string,
    titleClassName: string,
    labelClassName: string,
    buttonClassName: string
  })
};

export default CardError;
