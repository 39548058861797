import i18 from 'i18next';

import styles from './styles.module.scss';

export const references = [
  {
    className: styles.refNotAvailable,
    label: i18.t('Turns:calendarReferenceNotAvailable')
  },
  {
    className: styles.refAvailable,
    label: i18.t('Turns:calendarReferenceAvailable')
  },
  {
    className: styles.refSelected,
    label: i18.t('Turns:calendarReferenceSelected')
  }
];
