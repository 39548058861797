import React, { useReducer, useRef, Fragment } from 'react';
import { arrayOf, bool, string } from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from '@widergy/web-utils/lib/array';
import { formValueSelector } from 'redux-form';

import { officesType } from 'types/officeTypes';
import { CREATE_TURNS_USER_INFO } from 'constants/reduxForms';

import { getAttentionTypes } from './utils';
import { reducer, initialState, actionsCreator } from './reducer';
import SelectProcedure from './components/SelectProcedure';
import ContactInformation from './components/ContactInformation';
import SelectOffice from './components/SelectOffice';
import SelectDate from './components/SelectDate';
import SelectTime from './components/SelectTime';
import Summary from './components/Summary';
import ResultDialog from './components/ResultDialog';
import SubmitTurn from './components/SubmitTurn';
import styles from './styles.module.scss';
import SelectAttentionType from './components/SelectAttentionType';

const CreateTurns = ({ documentType, offices, officesLoading, utilityAttentionTypes }) => {
  const [values, ownDispatch] = useReducer(reducer, initialState);
  const actions = actionsCreator(ownDispatch);
  const refs = {
    procedure: useRef(null),
    contactInformation: useRef(null),
    office: useRef(null),
    date: useRef(null),
    time: useRef(null),
    attentionType: useRef(null)
  };

  const procedureOnchange = procedure => {
    if (values.selectedOffice) {
      const selectedOfficeData = offices.find(office => office.id === values.selectedOffice);
      if (selectedOfficeData && selectedOfficeData.hidden_forms?.includes(procedure)) {
        actions.handleSelectedOffice();
        actions.handleSelectedDate();
        actions.handleSelectedTime();
        actions.handleSelectedAttentionType();
      }
    }
    actions.handleSelectedProcedure(procedure);
  };

  const attentionTypes = getAttentionTypes(values.selectedOffice, offices, utilityAttentionTypes);
  return (
    <div className={styles.wrapper}>
      <SelectProcedure
        reference={refs.procedure}
        value={values.selectedProcedure}
        onChange={procedureOnchange}
      />
      {values.selectedProcedure && (
        <ContactInformation
          documentType={documentType}
          reference={refs.contactInformation}
          value={values.contactInformation}
          onChange={actions.handleContactInformation}
        />
      )}
      {values.contactInformation && (
        <SelectOffice
          reference={refs.office}
          values={values}
          offices={offices}
          officesLoading={officesLoading}
          onChange={actions.handleSelectedOffice}
          procedure={values.selectedProcedure}
        />
      )}
      {values.selectedOffice && !isEmpty(attentionTypes) && (
        <SelectAttentionType
          attentionTypes={attentionTypes}
          currentAttentionType={values.selectedAttentionType}
          onChange={actions.handleSelectedAttentionType}
          refAttentionTypes={refs.attentionType}
        />
      )}
      {(attentionTypes?.length > 0
        ? values.selectedOffice && values.selectedAttentionType
        : values.selectedOffice) && (
        <SelectDate reference={refs.date} values={values} onChange={actions.handleSelectedDate} />
      )}
      {values.selectedDate && (
        <SelectTime reference={refs.time} values={values} onChange={actions.handleSelectedTime} />
      )}
      {values.selectedTime && (
        <Fragment>
          <Summary refs={refs} values={values} />
          <SubmitTurn values={values} openResultDialog={actions.handleOpenResultDialog} />
        </Fragment>
      )}
      <ResultDialog isOpen={values.resultDialogOpen} handleClose={actions.handleCloseResultDialog} />
    </div>
  );
};

CreateTurns.propTypes = {
  documentType: string,
  offices: officesType,
  officesLoading: bool,
  utilityAttentionTypes: arrayOf(string)
};

const documentTypeSelector = formValueSelector(CREATE_TURNS_USER_INFO);

const mapStateToProps = store => ({
  documentType: documentTypeSelector(store, 'document_type'),
  offices: store.office.offices,
  officesLoading: store.office.officesLoading,
  utilityAttentionTypes: store.utility.configuration?.attention_type_values
});

export default connect(mapStateToProps)(CreateTurns);
