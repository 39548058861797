import Immutable from 'seamless-immutable';
import { completeReducer, createReducer } from 'redux-recompose';

import { actions } from './actions';

/* ------------- Auth reducer ------------- */
export const defaultState = {
  sessionLoading: true,
  signIn: null,
  signInLoading: false,
  username: null
};

const reducerDescription = {
  primaryActions: [actions.INIT, actions.SIGN_IN],
  override: {
    [actions.SET_ENTERED_URL]: (state, action) =>
      Immutable.merge(state, {
        enteredUrl: action.payload
      }),
    [actions.SET_USERNAME]: (state, action) => Immutable.merge(state, { username: action.payload })
  }
};

export const reducer = createReducer(Immutable(defaultState), completeReducer(reducerDescription));
