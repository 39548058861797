import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bool } from 'prop-types';

import { rechargeInformationType } from 'types/accountTypes';

import AccountSearch from '../../components/AccountSearch';

import EmergencyRechargeInformation from './components/EmergencyRechargeInformation';

const EmergencyRecharge = ({ rechargeInformation, loading }) => (
  <Fragment>
    <AccountSearch />
    {rechargeInformation.prepaidMeterNumber && !loading && (
      <EmergencyRechargeInformation rechargeInformation={rechargeInformation} />
    )}
  </Fragment>
);

EmergencyRecharge.propTypes = {
  rechargeInformation: rechargeInformationType,
  loading: bool
};

const mapStateToProps = store => ({
  rechargeInformation: store.account.emergencyRechargeInformation,
  loading: store.account.emergencyRechargeInformationLoading
});

export default connect(mapStateToProps)(EmergencyRecharge);
