import React from 'react';
import i18 from 'i18next';
import { getIn } from 'seamless-immutable';
import { connect } from 'react-redux';
import { bool, func, shape, string } from 'prop-types';
import { UTSelect } from '@widergy/energy-ui';

import { referenceType } from 'types/officeTypes';
import GridWrapper from 'app/screens/Dashboard/screens/Turns/components/GridWrapper';

import styles from './styles.module.scss';

const SelectProcedure = ({ onChange, reference, turnForms, value }) => (
  <GridWrapper
    reference={reference}
    title={i18.t('Turns:selectProcedureTitle')}
    titleClassName={styles.gridTitle}
  >
    <UTSelect
      classes={{ container: styles.selectContainer }}
      placeholder={i18.t('Turns:selectProcedurePlaceHolder')}
      options={turnForms}
      valueKey="value"
      labelKey="label"
      input={{ value, onChange }}
      meta={{}}
    />
  </GridWrapper>
);

SelectProcedure.propTypes = {
  onChange: func,
  reference: referenceType,
  turnForms: shape({
    form_ov: bool,
    label: string,
    value: string
  }),
  value: string
};

const mapStateToProps = store => ({
  turnForms: getIn(store, ['utility', 'configuration', 'turn_forms'])
});

export default connect(mapStateToProps)(SelectProcedure);
