import { shape, string, bool, number, arrayOf } from 'prop-types';

export const rechargeInformationType = shape({
  client_number: string,
  holder_name: string,
  address: string,
  available: bool,
  next_available_date: string,
  energy_amount: number,
  prepaidMeterNumber: string
});

export const rechargeHistoryDetailType = shape({
  client_number: string,
  datetime: string,
  status: string,
  payment_method: string,
  amount: number,
  performed_by: string,
  address: string,
  token: string,
  meter_number: string,
  transaction_code: string,
  ticket: string,
  energy_amount: number
});

export const rechargeHistoryType = arrayOf(rechargeHistoryDetailType);
