import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Switch } from 'react-router-dom';

import SignIn from 'app/screens/SignIn';
import Dashboard from 'app/screens/Dashboard';
import Maintenance from 'app/screens/Maintenance';
import { history } from 'redux/store';
import { ROUTES, ROUTE_TYPES } from 'constants/routes';

import RouteHandler from './components/RouteHandler';

const { PUBLIC, PRIVATE, RESTRICTED } = ROUTE_TYPES;

function Routes() {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <RouteHandler type={RESTRICTED} exact path={ROUTES.SIGN_IN} component={SignIn} />
        <RouteHandler type={PUBLIC} exact path={ROUTES.MAINTENANCE} component={Maintenance} />
        <RouteHandler type={PRIVATE} path={ROUTES.DASHBOARD} component={Dashboard} />
      </Switch>
    </ConnectedRouter>
  );
}

export default Routes;
