import React, { memo, useCallback } from 'react';
import { connect } from 'react-redux';
import i18 from 'i18next';
import { arrayOf, bool, string, instanceOf } from 'prop-types';
import ScrollBar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { isEmpty } from '@widergy/web-utils/lib/array';
import { UTLoading, UTLabel, UTVirtualizedList } from '@widergy/energy-ui';

import { turnType } from 'types/officeTypes';
import CardError from 'app/components/CardError';

import Turn from '../Turn';

import styles from './styles.module.scss';
import { turnsSelector } from './utils';

const TurnsList = ({ turns, turnsLoading, calendarLoading, error, officeWorkingDays }) => {
  const turnRenderer = useCallback(turn => <Turn turnData={turn} showOffice />, []);

  return (
    <UTLoading loading={turnsLoading || calendarLoading} className={styles.loading}>
      {error ? (
        <div>
          <CardError error={error} />
        </div>
      ) : (officeWorkingDays && isEmpty(officeWorkingDays)) || (turns && isEmpty(turns)) ? (
        <UTLabel darkGray large extrabold className={styles.label}>
          {i18.t('Turns:turnsByDayZeroResults')}
        </UTLabel>
      ) : (
        !isEmpty(turns) && (
          <div className={styles.turnsList}>
            <ScrollBar className={styles.scroll}>
              <UTVirtualizedList
                data={turns}
                overscan={10}
                elementsPerRow={1}
                itemRenderer={turnRenderer}
                itemPadding={20}
                effect="fadeIn"
                loading={turnsLoading}
                className={styles.list}
              />
            </ScrollBar>
          </div>
        )
      )}
    </UTLoading>
  );
};

TurnsList.propTypes = {
  calendarLoading: bool,
  error: string,
  officeWorkingDays: arrayOf(instanceOf(Date)),
  turns: arrayOf(turnType),
  turnsLoading: bool
};

const mapStateToProps = (store, props) => ({
  calendarLoading: store.office.officeWorkingDaysLoading,
  error: store.office.turnsByDayError,
  officeWorkingDays: store.office.officeWorkingDays,
  turns: turnsSelector(store, props),
  turnsLoading: store.office.turnsByDayLoading
});

export default connect(mapStateToProps)(memo(TurnsList));
