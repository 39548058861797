import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import i18 from 'i18next';
import { bool, func, string } from 'prop-types';
import { isEmpty } from '@widergy/web-utils/lib/array';

import { getAvailableOffices, mapOfficeOptions } from 'utils/officesUtils';
import GridSelector from 'app/screens/Dashboard/components/GridSelector';
import { referenceType, valuesType, officesType } from 'types/officeTypes';
import OfficeActions from 'redux/office/actions';
import { clientNumberField } from 'constants/fields';

import { handleScrollTo } from '../../utils';

import styles from './styles.module.scss';

const SelectOffice = ({ officesLoading, offices, onChange, reference, values, procedure }) => {
  const dispatch = useDispatch();
  const clientNumber = values.contactInformation[clientNumberField.key];

  const availableOffices = useMemo(() => getAvailableOffices(offices, procedure), [offices, procedure]);

  useEffect(() => {
    handleScrollTo(reference);
    if (values.selectedOffice) onChange(null);
    dispatch(OfficeActions.getOffices(clientNumber));
  }, [values.contactInformation]);

  return (
    <GridSelector
      classes={{
        optionsContainer: styles.gridContainer,
        gridWrapperContainer: styles.container,
        scrollbar: styles.scrollbar,
        titleClassName: styles.gridTitle,
        optionCardsClasses: { root: styles.gridCard }
      }}
      currentValue={values.selectedOffice}
      emptyMessage={i18.t('Turns:searchOfficesZeroResults')}
      handleSetValue={onChange}
      loading={officesLoading}
      options={mapOfficeOptions(availableOffices)}
      reference={reference}
      searchPlaceholder={i18.t('Turns:searchOfficesPlaceholder')}
      gridSizeMD={3}
      gridSizeXS={12}
      show={!isEmpty(offices)}
      title={i18.t('Turns:selectOfficeTitle')}
      scrollable
    />
  );
};

SelectOffice.propTypes = {
  offices: officesType,
  officesLoading: bool,
  onChange: func,
  reference: referenceType,
  values: valuesType,
  procedure: string
};

export default SelectOffice;
