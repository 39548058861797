import React from 'react';
import { reduxForm } from 'redux-form';
import { handleKeyDown } from '@widergy/utilitygo-forms-web';
import i18 from 'i18next';
import { func, bool, any } from 'prop-types';
import { UTButton } from '@widergy/energy-ui';

import { ACCOUNT_SEARCH } from 'constants/reduxForms';
import UTForm from 'app/components/UTForm';

import { FIELD_TYPE_PROPS } from './constants';
import styles from './styles.module.scss';

const AccountSearch = ({ handleSubmit, submitFailed, field, invalid, loading }) => (
  <form onSubmit={handleSubmit} onKeyDown={handleKeyDown(handleSubmit)} className={styles.content}>
    <UTForm fields={[field]} fieldTypeProps={FIELD_TYPE_PROPS} />
    <div className={styles.buttonContainer}>
      <UTButton disabled={submitFailed && invalid} loading={loading} type="submit" uppercase text>
        {i18.t('Commons:search')}
      </UTButton>
    </div>
  </form>
);

AccountSearch.propTypes = {
  handleSubmit: func,
  // eslint-disable-next-line react/forbid-prop-types
  field: any,
  loading: bool,
  submitFailed: bool,
  invalid: bool
};

export default reduxForm({ form: ACCOUNT_SEARCH, shouldError: () => true })(AccountSearch);
