import React, { Fragment, useEffect } from 'react';
import { UTCard, UTLabel, UTLoading, UTTabs, UTButton } from '@widergy/energy-ui';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { string, number, shape, bool, arrayOf, func } from 'prop-types';
import i18 from 'i18next';
import { getIn } from 'seamless-immutable';
import { isEmpty } from '@widergy/web-utils/lib/array';
import Error from '@material-ui/icons/Error';
import { createSelector } from 'reselect';
import Fade from 'react-reveal/Fade';

import UTTable from 'app/components/UTTable';
import AccountInfo from 'app/components/AccountInfo';
import { DIGITAL_BILL, HISTORY_ADHERENCE, HISTORY_SEND_BILL } from 'constants/routes';

import { TAB_OPTIONS, CONTENT } from './constants';
import styles from './styles.module.scss';
import { historyAdherenceSelector, historySendBillsSelector } from './selectors';

const HistoryContainer = ({ location, loading, address, accountNumber, data, dispatch }) => {
  useEffect(() => {
    if (!accountNumber) return dispatch(push(DIGITAL_BILL));
    if (data.action) dispatch(data.action(accountNumber));
  }, [accountNumber, location, dispatch]);

  const handleGetHistory = value => dispatch(push(value));

  const handleGoBack = () => {
    dispatch(data.cleanAction());
    dispatch(push(DIGITAL_BILL));
  };

  const header = (
    <div className={styles.header}>
      <UTLabel bold title>
        {i18.t('History:title')}
      </UTLabel>
      <UTButton text uppercase onPress={handleGoBack}>
        {i18.t('Commons:back')}
      </UTButton>
    </div>
  );

  return (
    <Fade>
      <div className={styles.container}>
        <AccountInfo address={address} extraInformation={[i18.t('History:nis', { accountNumber })]} />
        <UTCard className={styles.card} header={header}>
          <UTTabs
            value={location}
            options={TAB_OPTIONS}
            onChange={handleGetHistory}
            classes={{
              tabsRoot: styles.tabsRoot,
              tabRoot: styles.tabRoot,
              indicator: styles.indicator,
              label: styles.tabLabel
            }}
          />
          {loading ? (
            <UTLoading loading={loading} className={styles.loading} />
          ) : (
            <Fragment>
              {isEmpty(data.rows) ? (
                <div className={styles.containerDefaulText}>
                  <Error className={styles.icon} />
                  <UTLabel xxlarge bold gray>
                    {data.error || `${i18.t('History:historyEmpty')}`}
                  </UTLabel>
                </div>
              ) : (
                <UTTable columns={CONTENT[location].columns} rows={data.rows} />
              )}
            </Fragment>
          )}
        </UTCard>
      </div>
    </Fade>
  );
};

HistoryContainer.propTypes = {
  location: string,
  accountNumber: number,
  data: shape({
    rows: shape({
      prevEmail: string,
      email: string,
      channel: string,
      action: string,
      type: string,
      date: number,
      contactSource: string,
      username: string
    }),
    error: string,
    columns: arrayOf(
      shape({
        key: string,
        label: string
      })
    ),
    action: func
  }),
  loading: bool,
  address: string,
  error: string
};

export const historyDataSelector = createSelector(
  store => store.router.location.pathname,
  store => historyAdherenceSelector(store),
  store => historySendBillsSelector(store),
  store => store.history.historyAdherenceError,
  store => store.history.historySendBillsError,
  (path, adherence, sendBills, adherenceError, sendBillsError) => {
    if (CONTENT[path]) {
      const data = {
        [HISTORY_ADHERENCE]: {
          rows: adherence,
          error: adherenceError,
          columns: CONTENT[path].columns,
          action: CONTENT[path].action,
          cleanAction: CONTENT[path].cleanAction
        },
        [HISTORY_SEND_BILL]: {
          rows: sendBills,
          error: sendBillsError,
          columns: CONTENT[path].columns,
          action: CONTENT[path].action,
          cleanAction: CONTENT[path].cleanAction
        }
      };
      return data[path];
    }
    return {};
  }
);

const mapStateToProps = store => ({
  accountNumber: store.account.accountNumber,
  data: historyDataSelector(store),
  address: store.history.address,
  location: getIn(store, ['router', 'location', 'pathname']),
  loading: store.history.historySendBillsLoading || store.history.historyAdherenceLoading
});

export default connect(mapStateToProps)(HistoryContainer);
