import Immutable from 'seamless-immutable';

import { actions } from './actions';

/* ------------- Alert reducer ------------- */

export const defaultState = {
  alertDialogOpen: false,
  alertDialogTitle: null,
  alertDialogContent: null,
  alertDialogOnDismiss: null,
  shouldGoToDashboard: false
};

/* eslint-disable complexity */
export function reducer(state = Immutable(defaultState), action) {
  switch (action.type) {
    case actions.OPEN_ALERT_DIALOG: {
      return Immutable.merge(state, {
        alertDialogOpen: true,
        alertDialogTitle: action.payload.title || null,
        alertDialogContent: action.payload.content || null,
        alertDialogOnDismiss: action.payload.onDismiss,
        alertDialogContentType: action.payload.contentType || null,
        shouldGoToDashboard: action.payload.shouldGoToDashboard || false,
        unlockScroll: action.payload.unlockScroll || false
      });
    }
    case actions.CLOSE_ALERT_DIALOG: {
      return Immutable.merge(state, {
        alertDialogOpen: false,
        shouldGoToDashboard: false
      });
    }
    default: {
      return state;
    }
  }
}
/* eslint-enable complexity */
