import i18 from 'i18next';
import moment from 'moment';

import appConfig from 'config/appConfig';
import { capitalizeWord } from 'utils/stringUtils';
import { TURN_STATUS } from 'constants/turns';

const { withAssignment, withLastActivity } = appConfig.turns;

const userName = user => user && `${user.first_name} ${user.last_name}`;
const hasName = user => !!user?.first_name && !!user?.last_name;

export const turnLabelsInformation = (turnData, userId, showOffice) => {
  const assignedTo = turnData.assigned_to;
  const lastOperationBy = turnData.last_operation_by;
  const statusIsPending = turnData.status === TURN_STATUS.PENDING;
  const isTakenByMe = assignedTo?.id === userId;
  const shouldShowLastOperation =
    withLastActivity && !assignedTo && !statusIsPending && hasName(lastOperationBy);
  const shouldShowAssignedTo = withAssignment && hasName(assignedTo);

  return [
    {
      labelText: `${i18.t('Turns:date')}`,
      valueText: `${moment(turnData.date).format('DD/MM/YYYY')}&nbsp; ${
        turnData.working_day_slot.begins_at
      } &#45; ${turnData.working_day_slot.ends_at}`,
      shouldShow: true
    },
    {
      labelText: `${i18.t('Turns:office')}`,
      valueText:
        turnData.office_city || turnData.office_address
          ? `${turnData.office_city || ''} ${turnData.office_address || ''}`
          : '&#45;',
      shouldShow: showOffice
    },
    {
      labelText: `${i18.t('Turns:attentionType')}`,
      valueText: `${capitalizeWord(turnData.attention_type || ' ')}`,
      shouldShow: showOffice && !!turnData.attention_type
    },
    {
      labelText: `${i18.t(`Turns:takenBy`)}`,
      valueText: `${userName(assignedTo)} ${isTakenByMe ? i18.t('Turns:takenByMeAclaration') : ''}`,
      shouldShow: shouldShowAssignedTo
    },
    {
      labelText: `${i18.t(`Turns:lastOperationBy`)}`,
      valueText: userName(lastOperationBy),
      shouldShow: shouldShowLastOperation
    }
  ];
};
