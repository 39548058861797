import React from 'react';
import { func, number } from 'prop-types';
import { UTTouchableWithoutFeedback } from '@widergy/energy-ui';

import { workingDaySlotType } from 'types/officeTypes';

import styles from './styles.module.scss';

const SlotsList = ({ officeWorkingDaySlots, selectSlot, selectedValue }) => {
  const onClick = slot => selectSlot(slot.id);

  return (
    <div className={styles.slotsContainer}>
      {officeWorkingDaySlots.map(slot => (
        <UTTouchableWithoutFeedback
          onClick={() => onClick(slot)}
          className={`${styles.slot} ${selectedValue === slot.id && styles.selected}`}
        >
          {`${slot.begins_at} - ${slot.ends_at}`}
        </UTTouchableWithoutFeedback>
      ))}
    </div>
  );
};

SlotsList.propTypes = {
  officeWorkingDaySlots: workingDaySlotType,
  selectSlot: func,
  selectedValue: number
};

export default SlotsList;
