import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

import edenor from 'config/utilities/edenor/themes.js';
import edelap from 'config/utilities/edelap/themes.js';
import eden from 'config/utilities/eden/themes.js';
import edes from 'config/utilities/edes/themes.js';
import edesa from 'config/utilities/edesa/themes.js';
import edea from 'config/utilities/edea/themes.js';

const themes = {
  edenor,
  edelap,
  eden,
  edes,
  edesa,
  edea
};
export const energyUITheme = themes[process.env.REACT_APP_UTILITY_NAME].eui;

export const muiTheme = createMuiTheme(themes[process.env.REACT_APP_UTILITY_NAME].mui);
