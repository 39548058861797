import { stringArrayToObject } from '../utils';

/* ------------- Alert actions ------------- */
export const actions = stringArrayToObject(['OPEN_ALERT_DIALOG', 'CLOSE_ALERT_DIALOG'], '@@ALERT');

export const actionCreators = {
  openAlertDialog(options) {
    return {
      type: actions.OPEN_ALERT_DIALOG,
      payload: {
        ...options
      }
    };
  },
  closeAlertDialog: () => dispatch => {
    dispatch({ type: actions.CLOSE_ALERT_DIALOG });
  }
};

export default actionCreators;
