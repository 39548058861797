import React, { PureComponent } from 'react';
import { arrayOf, shape, any, string, node } from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Header from './components/Header';
import styles from './styles.module.scss';

class DesktopTable extends PureComponent {
  render() {
    const { rows = [], columns = [] } = this.props;

    return (
      <div className={styles.root}>
        <Paper className={styles.paper}>
          <div className={styles.tableWrapper}>
            <Table className={styles.table} aria-labelledby="tableTitle" size="medium">
              <Header columns={columns} />
              <TableBody>
                {rows.map(row => (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    {columns.map(column => (
                      <TableCell
                        className={row[`${column.key}ClassName`]}
                        key={column.key}
                        align={column.align || 'left'}
                      >
                        {row[column.key] || '-'}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Paper>
      </div>
    );
  }
}

DesktopTable.propTypes = {
  rows: arrayOf(shape(any)),
  columns: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
      cellRenderer: node
    })
  )
};

export default DesktopTable;
