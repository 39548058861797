import { push } from 'connected-react-router';

import AuthActions from 'redux/auth/actions';
import { DEFAULT_ROUTES, PUBLIC_ROUTES, RESTRICTED_ROUTES } from 'constants/routes';
import appConfig from 'config/appConfig';

export const navigateToDefaultRoute = (dispatch, getState) => {
  const { currentUser } = getState().auth;
  if (currentUser) {
    dispatch(push(appConfig.defaultRoute || DEFAULT_ROUTES.PRIVATE));
  } else {
    dispatch(push(DEFAULT_ROUTES.RESTRICTED));
  }
};

export const initialNavigation = async (dispatch, getState) => {
  const { enteredUrl } = getState().auth;
  const { currentUser } = getState().auth;
  const { pathname } = getState().router.location;
  const redirectURL = enteredUrl;
  if (!currentUser) {
    if (
      !PUBLIC_ROUTES.includes(pathname) &&
      redirectURL &&
      RESTRICTED_ROUTES.includes(redirectURL.pathname)
    ) {
      await dispatch(AuthActions.setEnteredUrl(null));
      if (redirectURL) await dispatch(push(redirectURL));
      else navigateToDefaultRoute(dispatch, getState);
    }
  } else {
    await dispatch(AuthActions.setEnteredUrl(null));
    if (redirectURL) await dispatch(push(redirectURL));
    else navigateToDefaultRoute(dispatch, getState);
  }
};
