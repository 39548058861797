import { createTypes, completeTypes } from 'redux-recompose';
import { push } from 'connected-react-router';
import i18 from 'i18next';
import { AlertHandler } from '@widergy/energy-ui';
import { retrieveErrors } from '@widergy/web-utils/lib/message';
import { existsErrorCode } from '@widergy/web-utils/lib/errorHandling';
import {
  NOT_CONFIRMED,
  INVALID_CREDENTIALS,
  NETWORK_ERROR,
  TIMEOUT_ERROR
} from '@widergy/web-utils/lib/constants/errorCodes';

import UserActions from 'redux/user/actions';
import AuthService, { saveSession } from 'services/AuthService';
import { initialNavigation } from 'utils/routerUtils';
import { ROUTES } from 'constants/routes';

import { healthCheckAction } from './utils';

/* ------------- Auth actions ------------- */
export const actions = createTypes(
  completeTypes(['INIT', 'SIGN_IN'], ['SET_ENTERED_URL', 'SET_USERNAME']),
  '@@AUTH'
);

const privateActionCreators = {
  initSuccess: () => ({
    type: actions.INIT_SUCCESS,
    target: 'session'
  }),
  initFailure: () => ({
    type: actions.INIT_FAILURE,
    target: 'session'
  })
};

export const actionCreators = {
  init: avoidNavigation => async (dispatch, getState) => {
    dispatch({ type: actions.INIT, target: 'session' });
    await healthCheckAction();
    const storedSession = AuthService.authInit();
    const { healthCheck } = getState().utility;
    const healthCheckValid = healthCheck && healthCheck.healthy;
    if (storedSession && healthCheckValid) {
      await dispatch(UserActions.getUserInformation());
      const { currentUser } = getState().user;
      if (!currentUser) {
        dispatch(actionCreators.signOut());
        dispatch(privateActionCreators.initFailure());
        if (!avoidNavigation) await initialNavigation(dispatch, getState);
      } else {
        dispatch(privateActionCreators.initSuccess());
        if (!avoidNavigation) await initialNavigation(dispatch, getState);
      }
    } else {
      dispatch(privateActionCreators.initFailure());
      if (!avoidNavigation) await initialNavigation(dispatch, getState);
    }
  },
  signIn: (authData, avoidNavigation) => async dispatch => {
    dispatch({ type: actions.SIGN_IN, target: 'signIn' });
    const response = await AuthService.signIn(authData);
    if (response.ok) {
      dispatch(actionCreators.signInSuccess(response, avoidNavigation));
    } else {
      dispatch(actionCreators.signInFailure(response, authData));
    }
  },
  signInSuccess: (response, avoidNavigation) => async dispatch => {
    saveSession(response.data.access_token);
    dispatch({
      type: actions.SIGN_IN_SUCCESS,
      target: 'signIn',
      payload: response.data
    });
    dispatch(actionCreators.init(avoidNavigation));
  },
  signInFailure: (response, authData = {}) => async dispatch => {
    if (existsErrorCode(NOT_CONFIRMED, response.data)) {
      dispatch(actionCreators.setEmailValidationRequired());
      const userInfo = {
        email: response.data.email,
        provider: response.data.provider,
        password: authData && authData.password,
        uid: response.data.uid
      };
      AlertHandler.info(i18.t('SignUp:notConfirmed'));
      dispatch(actionCreators.restartSignUp(4, userInfo));
      dispatch({ type: actions.SIGN_IN_FAILURE, target: 'signIn', error: NOT_CONFIRMED });
    } else {
      const conectionIssue = response.problem === NETWORK_ERROR || response.problem === TIMEOUT_ERROR;
      if (existsErrorCode(INVALID_CREDENTIALS, response.data)) {
        dispatch({ type: actions.SIGN_IN_FAILURE, target: 'signIn', error: INVALID_CREDENTIALS });
      } else if (conectionIssue) {
        dispatch({ type: actions.SIGN_IN_FAILURE, target: 'signIn', error: 'NETWORK_ERROR' });
      } else {
        dispatch({ type: actions.SIGN_IN_FAILURE, target: 'signIn' });
      }
      AlertHandler.error(retrieveErrors(response.data, i18.t('DefaultMessages:signInFailure')));
    }
    dispatch(privateActionCreators.initFailure());
  },

  signOut: origin => async dispatch => {
    AuthService.signOut();
    dispatch(UserActions.removeUser(origin));
    dispatch(push(ROUTES.SIGN_IN));
  },
  setEnteredUrl: url => ({
    type: actions.SET_ENTERED_URL,
    payload: url
  }),
  setUsername: username => ({
    type: actions.SET_USERNAME,
    payload: username
  })
};

export default actionCreators;
