import Immutable from 'seamless-immutable';
import { completeReducer, createReducer } from 'redux-recompose';

import { actions } from './actions';

/* ------------- Account reducer ------------- */
export const defaultState = {
  accountNumber: null,
  accountEmail: {},
  emergencyRechargeInformation: {},
  emergencyRechargeInformationLoading: false,
  emergencyRechargeInformationError: null,
  emergencyRecharge: null,
  emergencyRechargeLoading: false,
  emergencyRechargeError: null,
  rechargeHistoryLoading: false
};
const reducerDescription = {
  primaryActions: [
    actions.GET_ACCOUNT_EMAIL,
    actions.ADD_EMAIL,
    actions.EDIT_EMAIL,
    actions.REMOVE_EMAIL,
    actions.GET_EMERGENCY_RECHARGE_INFORMATION,
    actions.EMERGENCY_RECHARGE,
    actions.GET_RECHARGE_HISTORY
  ],
  override: {
    [actions.SET_ACCOUNT_NUMBER]: (state, action) =>
      Immutable.merge(state, {
        ...state,
        accountNumber: action.payload
      }),
    [actions.GET_ACCOUNT_EMAIL]: state =>
      Immutable.merge(state, {
        ...state,
        accountEmail: defaultState.accountEmail,
        accountEmailLoading: true
      }),
    [actions.CLEAN_DATA]: state =>
      Immutable.merge(state, {
        accountEmail: defaultState.accountEmail,
        accountNumber: defaultState.accountNumber,
        emergencyRechargeInformation: defaultState.emergencyRechargeInformation,
        emergencyRechargeInformationError: defaultState.emergencyRechargeInformationError,
        emergencyRecharge: defaultState.emergencyRecharge,
        emergencyRechargeError: defaultState.emergencyRechargeError
      })
  }
};

export const reducer = createReducer(Immutable(defaultState), completeReducer(reducerDescription));
