import React from 'react';
import { func, string, bool, number } from 'prop-types';
import i18 from 'i18next';

import UTForm from 'app/components/UTForm';
import UTDialog from 'app/components/UTDialog';
import appConfig from 'config/appConfig';

import { configDialog } from '../../constants';

import styles from './styles.module.scss';
import FormDigitalBill from './layout';

const DialogDigitalBill = ({
  userAction,
  onClose,
  isOpen,
  handleSubmit,
  handleAccept,
  loading,
  email,
  clientNumber,
  invalid
}) => {
  const { title, fields } = configDialog(clientNumber)[userAction] || {};

  return (
    <UTDialog
      withCloseButton
      elevation={appConfig.dialogElevation}
      flatButton={appConfig.flatButton}
      title={title}
      acceptButton={{
        onPress: handleAccept,
        disabled: invalid,
        loading,
        text: i18.t('Commons:accept'),
        config: { ...appConfig.dialogButton.accept, uppercase: true }
      }}
      cancelButton={{
        onPress: onClose,
        disabled: loading,
        text: i18.t('Commons:cancel'),
        config: { ...appConfig.dialogButton.cancel, uppercase: true }
      }}
      isOpen={isOpen}
      onRequestClose={onClose}
      classNames={{
        content: styles.dialog
      }}
      alertConfig={appConfig.dialogLabel}
    >
      {!!userAction && (
        <FormDigitalBill onSubmit={handleSubmit} initialValues={{ email }}>
          <UTForm fields={fields} />
        </FormDigitalBill>
      )}
    </UTDialog>
  );
};

DialogDigitalBill.propTypes = {
  onClose: func,
  isOpen: func,
  userAction: string,
  handleAccept: func,
  handleSubmit: func,
  loading: bool,
  invalid: bool,
  email: string,
  clientNumber: number
};

export default DialogDigitalBill;
