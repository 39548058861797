import React from 'react';
import { connect } from 'react-redux';
import i18 from 'i18next';
import { bool } from 'prop-types';
import { push } from 'connected-react-router';
import { UTLabel, UTCard, UTTooltip, UTIconButton, UTButton } from '@widergy/energy-ui';
import History from '@material-ui/icons/History';

import AccountActions from 'redux/account/actions';
import { rechargeInformationType } from 'types/accountTypes';
import { ROUTES } from 'constants/routes';

import { formatInformation } from './constants';
import styles from './styles.module.scss';

const informationRenderer = item =>
  item.show && (
    <div className={styles.rowContainer}>
      <UTLabel light gray>
        {item.label}
      </UTLabel>
      <UTLabel bold gray large className={styles.itemDetail}>
        {item.value}
      </UTLabel>
    </div>
  );

const EmergencyRechargeInformation = ({ rechargeInformation, loading, dispatch }) => {
  const handleRecharge = () =>
    dispatch(
      AccountActions.emergencyRecharge(
        rechargeInformation.prepaidMeterNumber,
        rechargeInformation.client_number,
        rechargeInformation.address
      )
    );

  const handleGoToHistory = () => dispatch(push(ROUTES.RECHARGE_HISTORY));

  const information = formatInformation(rechargeInformation);
  return (
    <UTCard className={styles.card}>
      <div className={styles.containerTitle}>
        <UTLabel large bold>
          {i18.t('RechargeInformation:title')}
        </UTLabel>
        <UTTooltip interactive={false} content={i18.t('Commons:history')}>
          <span>
            <UTIconButton onClick={handleGoToHistory}>
              <History />
            </UTIconButton>
          </span>
        </UTTooltip>
      </div>
      <hr className={styles.hr} />
      <div className={styles.informationContainer}>{information.map(informationRenderer)}</div>
      <div className={styles.buttonContainer}>
        <UTButton
          disabled={!rechargeInformation.available}
          loading={loading}
          onPress={handleRecharge}
          uppercase
          text
        >
          {i18.t('RechargeInformation:recharge')}
        </UTButton>
      </div>
    </UTCard>
  );
};

EmergencyRechargeInformation.propTypes = {
  rechargeInformation: rechargeInformationType,
  loading: bool
};

const mapStateToProps = store => ({
  rechargeInformation: store.account.emergencyRechargeInformation,
  loading: store.account.emergencyRechargeLoading
});

export default connect(mapStateToProps)(EmergencyRechargeInformation);
