import React, { PureComponent } from 'react';
import { bool, string, func } from 'prop-types';
import { connect } from 'react-redux';
import i18 from 'i18next';
import { push } from 'connected-react-router';
import { UTLabel } from '@widergy/energy-ui';

import AlertDialogActions from 'redux/alertDialog/actions';
import appConfig from 'config/appConfig';
import { lockScreen } from 'utils/browserUtils';
import { ROUTES } from 'constants/routes';
import UTDialog from 'app/components/UTDialog';

import styles from './styles.module.scss';
import { pickIcon, ERROR } from './utils';

const config = appConfig.alertDialog;

class UTAlertDialog extends PureComponent {
  componentDidUpdate(prevProps) {
    if (this.props.shouldGoToDashboard !== prevProps.shouldGoToDashboard) {
      this.props.dispatch(push(ROUTES.DASHBOARD));
    }
    if (this.props.dialogIsOpen && !prevProps.dialogIsOpen) {
      if (this.props.unlockScroll) lockScreen(true);
    }
  }

  handleCloseDialog = () => {
    this.props.dispatch(AlertDialogActions.closeAlertDialog());
    if (this.props.onDismiss) this.props.onDismiss();
    if (this.props.unlockScroll) lockScreen(false);
  };

  render() {
    const {
      dialogIsOpen,
      dialogTitle,
      dialogContent,
      dialogContentType,
      unlockScroll,
      ...props
    } = this.props;

    return (
      <UTDialog
        withCloseButton
        elevation={appConfig.dialogElevation}
        isOpen={dialogIsOpen}
        disableScrollLock={unlockScroll}
        onRequestClose={this.handleCloseDialog}
        title=" "
        acceptButton={{
          text: i18.t('Commons:agreed'),
          onPress: this.handleCloseDialog,
          className: styles.accept,
          config: { ...appConfig.dialogButton.accept }
        }}
        classNames={{
          container: styles.dialog
        }}
        alertConfig={appConfig.dialogLabel}
        {...props}
        {...config.dialogProps}
      >
        <div className={styles.alertContentContainer}>
          {dialogContentType && (
            <img alt="alertIcon" src={pickIcon(dialogContentType)} className={styles.alertImage} />
          )}
          <UTLabel xxlarge semibold className={styles.title} {...config.textProps}>
            {dialogTitle ||
              (dialogContentType === ERROR
                ? i18.t('Alerts:defaultErrorTitle')
                : i18.t('Alerts:defaultTitle'))}
          </UTLabel>
          <UTLabel large className={styles.content} {...config.textProps}>
            {dialogContent}
          </UTLabel>
        </div>
      </UTDialog>
    );
  }
}

UTAlertDialog.propTypes = {
  dialogIsOpen: bool,
  dialogTitle: string,
  dialogContent: string,
  onDismiss: func,
  dialogContentType: string,
  shouldGoToDashboard: bool,
  unlockScroll: bool
};

const mapStateToProps = store => ({
  dialogIsOpen: store.alertDialog.alertDialogOpen,
  dialogTitle: store.alertDialog.alertDialogTitle,
  dialogContent: store.alertDialog.alertDialogContent,
  onDismiss: store.alertDialog.alertDialogOnDismiss,
  dialogContentType: store.alertDialog.alertDialogContentType,
  shouldGoToDashboard: store.alertDialog.shouldGoToDashboard,
  unlockScroll: store.alertDialog.unlockScroll
});

export default connect(mapStateToProps)(UTAlertDialog);
