import { create } from 'apisauce';
import Rollbar from 'rollbar';
import { createApiMonitors, API_MONITORS } from '@widergy/web-utils/lib/api';

import store from 'redux/store';

const api = create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: { 'Utility-ID': process.env.REACT_APP_UTILITY_ID, Channel: 'backoffice' },
  timeout: 30000
});

const getCurrentUser = () => store.getState().user.currentUser;

const monitorsOptions = {
  [API_MONITORS.MISSING_PARAMETER]: { getMonitorParams: () => ({ currentUser: getCurrentUser() }) },
  [API_MONITORS.INVALID_PARAMETERS]: { getMonitorParams: () => ({ currentUser: getCurrentUser() }) },
  [API_MONITORS.USER_ERROR]: { getMonitorParams: () => ({ currentUser: getCurrentUser() }) },
  [API_MONITORS.RECORD_NOT_UNIQUE]: { getMonitorParams: () => ({ currentUser: getCurrentUser() }) },
  [API_MONITORS.ERROR_USER_UPDATE]: { getMonitorParams: () => ({ currentUser: getCurrentUser() }) }
};

export const CONFIG_TIMEOUT = 40000;
export const DEFAULT_RETRIES = 2;

export const apiSetup = () => {
  createApiMonitors(api, Rollbar, monitorsOptions);
};

export default api;
