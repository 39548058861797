export const DASHBOARD = '/';
export const SIGN_IN = '/ingreso';
export const DIGITAL_BILL = '/cuentas/factura_digital';
export const EMERGENCY_RECHARGE = '/recarga_SOS';
export const HISTORY_ADHERENCE = '/cuentas/factura_digital/historial_adhesiones';
export const HISTORY_SEND_BILL = '/cuentas/factura_digital/historial_envio_de_facturas';
export const MANAGE_TURNS = '/turnos';
export const CREATE_TURNS = '/turnos/alta';
export const MAINTENANCE = '/mantenimiento';
export const RECHARGE_HISTORY = '/recargas/historial';

export const ROUTES = {
  DASHBOARD,
  SIGN_IN,
  DIGITAL_BILL,
  EMERGENCY_RECHARGE,
  HISTORY_ADHERENCE,
  HISTORY_SEND_BILL,
  MANAGE_TURNS,
  CREATE_TURNS,
  RECHARGE_HISTORY,
  MAINTENANCE
};

export const DEFAULT_ROUTES = {
  RETRIEVE_SESSION: ROUTES.SIGN_IN,
  PRIVATE: ROUTES.DASHBOARD,
  RESTRICTED: ROUTES.SIGN_IN
};

export const ROUTE_TYPES = {
  PRIVATE: 'private',
  PUBLIC: 'public',
  RESTRICTED: 'restricted'
};

export const PUBLIC_ROUTES = [ROUTES.MAINTENANCE];

export const RESTRICTED_ROUTES = [ROUTES.SIGN_IN];
