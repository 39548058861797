import React, { useMemo } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { shape, string } from 'prop-types';

import { UTILITIES } from 'config/common';
import appConfig from 'config/appConfig';
import UTTopbar from 'app/components/UTTopbar';
import UTFooter from 'app/components/UTFooter';
import RouteHandler from 'app/components/Routes/components/RouteHandler';
import { DASHBOARD, DIGITAL_BILL, EMERGENCY_RECHARGE } from 'constants/routes';

import { routes } from './constants';
import styles from './styles.module.scss';

const Dashboard = ({ location }) => {
  const customRoutes = useMemo(
    () =>
      routes[process.env.REACT_APP_UTILITY_NAME].map(_route => (
        <RouteHandler key={_route.path} {..._route} />
      )),
    []
  );

  const showTabs =
    (location.pathname === DIGITAL_BILL || location.pathname === EMERGENCY_RECHARGE) &&
    process.env.REACT_APP_UTILITY_NAME === UTILITIES.EDENOR;

  return (
    <div className={styles.container}>
      <UTTopbar className={showTabs && styles.showTabsTopbar} />
      <div className={`${styles.content} ${showTabs && styles.showTabsContent}`}>
        <Switch>
          {customRoutes}
          <Route render={() => <Redirect to={appConfig.defaultRoute || DASHBOARD} />} />
        </Switch>
      </div>
      {appConfig.footer.enabled && <UTFooter />}
    </div>
  );
};

Dashboard.propTypes = {
  location: shape({
    pathname: string,
    search: string
  })
};

export default Dashboard;
