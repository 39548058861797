import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { getIn } from 'seamless-immutable';
import { bool } from 'prop-types';
import { UTLabel } from '@widergy/energy-ui';

import AccountActions from 'redux/account/actions';
import { rechargeInformationType, rechargeHistoryType } from 'types/accountTypes';
import { ROUTES } from 'constants/routes';
import appConfig from 'config/appConfig';
import UTDialog from 'app/components/UTDialog';

import RechargeItem from './components/RechargeItem';
import RechargeHistory from './layout';

const RechargeHistoryContainer = ({ dispatch, rechargeInformation, loading, recharges }) => {
  const { client_number: clientNumber } = rechargeInformation;
  useEffect(() => {
    if (!clientNumber) dispatch(push(ROUTES.DASHBOARD));
    else dispatch(AccountActions.getRechargeHistory(clientNumber));
  }, [rechargeInformation]);

  const [ticket, setTicket] = useState();
  const [ticketDialogOpen, setTicketDialogOpen] = useState(false);

  const handleGoBack = () => dispatch(push(ROUTES.EMERGENCY_RECHARGE));

  const handleTicketDialogOpen = ticketToSet => {
    setTicket(ticketToSet);
    setTicketDialogOpen(true);
  };

  const handleTicketDialogClose = () => setTicketDialogOpen(false);

  const rechargeRenderer = recharge => (
    <RechargeItem recharge={recharge} loading={loading} onTicketDialogOpen={handleTicketDialogOpen} />
  );

  return (
    <Fragment>
      <RechargeHistory
        handleGoBack={handleGoBack}
        rechargeInformation={rechargeInformation}
        loading={loading}
        recharges={recharges}
        rechargeRenderer={rechargeRenderer}
      />
      <UTDialog
        withCloseButton
        isOpen={ticketDialogOpen}
        onRequestClose={handleTicketDialogClose}
        alertConfig={appConfig.dialogLabel}
        elevation={appConfig.dialogElevation}
      >
        <UTLabel>{ticket}</UTLabel>
      </UTDialog>
    </Fragment>
  );
};

const mapStateToProps = store => ({
  rechargeInformation: getIn(store, ['account', 'emergencyRechargeInformation']),
  recharges: getIn(store, ['account', 'rechargeHistory'], []),
  loading: store.account.rechargeHistoryLoading
});

RechargeHistoryContainer.propTypes = {
  rechargeInformation: rechargeInformationType,
  recharges: rechargeHistoryType,
  loading: bool
};

export default connect(mapStateToProps)(RechargeHistoryContainer);
