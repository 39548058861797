import FontSizes from 'scss/variables/_fontSizes.scss';
import AppConfig from 'config/appConfig';

import 'fonts/edesa/HelveticaNeue.css';
import Colors from './scss/_colors.scss';

const eui = {
  UTButton: {
    base: {
      backgroundColor: Colors.primary,
      borderRadius: 50,
      boxShadow: 'none',
      color: Colors.white,
      fontWeight: 500,
      hover: {
        backgroundColor: Colors.primaryHover,
        boxShadow: 'none'
      },
      padding: '10px 35px'
    },
    baseOutlined: {
      border: `2px solid ${Colors.primary}`,
      borderRadius: 50,
      color: Colors.primary,
      fontWeight: 500,
      hover: {
        backgroundColor: Colors.primaryHover,
        color: Colors.white
      },
      padding: '10px 35px'
    },
    baseText: {
      borderRadius: 50,
      color: Colors.primary,
      fontWeight: 500,
      hover: {
        color: Colors.primaryHover
      },
      padding: '10px 35px'
    },
    secondary: {
      backgroundColor: Colors.tertiary,
      color: Colors.white,
      hover: {
        backgroundColor: Colors.tertiaryHover
      }
    },
    secondaryOutlined: {
      border: `2px solid ${Colors.tertiary}`,
      color: Colors.tertiary,
      hover: {
        backgroundColor: Colors.flatTertiaryHover
      }
    },
    secondaryText: {
      color: Colors.tertiary,
      hover: {
        backgroundColor: Colors.flatTertiaryHover
      }
    },
    alternative: {
      backgroundColor: Colors.secondary,
      color: Colors.white,
      hover: {
        backgroundColor: Colors.secondaryHover
      }
    },
    alternativeOutlined: {
      border: `2px solid ${Colors.secondary}`,
      color: Colors.secondary,
      hover: {
        backgroundColor: Colors.flatSecondaryHover
      }
    },
    alternativeText: {
      color: Colors.secondary,
      hover: {
        backgroundColor: Colors.flatSecondaryHover
      }
    },
    gray: {
      backgroundColor: Colors.gray,
      color: Colors.white
    },
    grayOutlined: {
      border: `2px solid ${Colors.gray}`,
      color: Colors.gray
    },
    grayText: {
      color: Colors.gray
    },
    disabled: {
      backgroundColor: Colors.disabledPrimary,
      color: Colors.white
    },
    disabledOutlined: {
      border: `2px solid ${Colors.disabledPrimary}`,
      borderColor: Colors.disabledPrimary,
      color: Colors.disabledPrimary
    },
    disabledText: {
      color: Colors.disabledPrimary
    },
    loading: {
      color: Colors.white
    },
    loadingText: {
      color: Colors.primary
    },
    loadingOutlined: {
      color: Colors.primary
    }
  },
  Fonts: {
    fontFamily: AppConfig.fontFamily,
    xxxsmall: FontSizes.xxxsmall,
    xxsmall: FontSizes.xxsmall,
    xsmall: FontSizes.xsmall,
    small: FontSizes.small,
    medium: FontSizes.medium,
    large: FontSizes.large,
    xlarge: FontSizes.xlarge,
    xxlarge: FontSizes.xxlarge,
    xxxlarge: FontSizes.xxxlarge,
    title: FontSizes.title,
    largeTitle: FontSizes.largeTitle
  },
  UTDialog: {
    mainTitle: {
      display: 'flex',
      marginRight: '60px'
    },
    warning: {
      maxWidth: '260px',
      padding: '24px 24px 0'
    },
    acceptButton: {
      margin: '0 10px'
    }
  },
  UTSelect: {
    underline: {
      hover: { borderBottom: `1px solid ${Colors.gray2}` },
      disabled: { borderBottom: `1px solid ${Colors.disabledGray}` },
      focused: { borderBottom: `2px solid ${Colors.lightPrimary}` },
      base: { borderBottom: `2px solid ${Colors.lightPrimary}` },
      unfocused: { borderBottom: `1px solid ${Colors.gray2}` }
    },
    formLabel: {
      color: Colors.gray,
      errorColor: Colors.gray,
      focusedColor: Colors.lightPrimary,
      focusedErrorColor: Colors.errorRed
    }
  },
  UTTextInput: {
    underline: {
      hover: { borderBottom: `1px solid ${Colors.gray2}` },
      disabled: { borderBottom: `1px solid ${Colors.disabledGray}` },
      focused: { borderBottom: `2px solid ${Colors.primary}` },
      base: { borderBottom: `2px solid ${Colors.primary}` },
      unfocused: { borderBottom: `1px solid ${Colors.gray2}` }
    },
    formLabel: {
      color: Colors.gray,
      errorColor: Colors.gray,
      focusedColor: Colors.primary,
      focusedErrorColor: Colors.errorRed
    }
  },
  UTLabel: {
    base: {
      fontSize: FontSizes.medium
    },
    primary: {
      color: Colors.primary
    },
    secondary: {
      color: Colors.tertiary
    },
    alternative: {
      color: Colors.secondary
    },
    gray: {
      color: Colors.gray
    },
    error: {
      color: Colors.errorRed
    }
  },
  Loading: {
    base: {
      color: Colors.loadingBase,
      size: 85,
      thickness: 1.5
    }
  },
  UTSwitch: {
    container: {
      width: '34px',
      height: '14px',
      margin: '12px 0'
    },
    sliderBack: {
      backgroundColor: Colors.gray2,
      border: 'none',
      checkedSlider: {
        border: 'none',
        backgroundColor: Colors.tertiary,
        hover: {
          backgroundColor: Colors.primaryHover
        }
      }
    },
    slider: {
      width: '20px',
      height: '20px',
      marginLeft: '-22px',
      backgroundColor: Colors.grayLight,
      checkedSlider: {
        backgroundColor: Colors.primary,
        transform: 'translateX(22px)'
      },
      boxShadow:
        '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
      transition: 'all 0.3s ease-in-out, transform 150ms'
    },
    checkedText: {
      color: Colors.white,
      fontSize: '12.8px',
      fontWeight: '500'
    },
    uncheckedText: {
      fontSize: '12.8px',
      fontWeight: '500'
    }
  }
};
const mui = {
  typography: {
    fontFamily: AppConfig.fontFamily
  },
  overrides: {
    MuiInput: {
      input: {
        fontSize: FontSizes.medium,
        '&:disabled': {
          color: Colors.disabledGray
        }
      },
      underline: {
        // On hover
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: `1px solid ${Colors.gray2}`
        },
        // After submitting error without hover
        '&$disabled:before': {
          borderBottom: `1px dotted ${Colors.disabledGray}`
        },
        // Without hover
        '&:before': {
          borderBottom: `1px solid ${Colors.gray2}`
        },
        // Border growth
        '&$focused:after': {
          borderBottom: `2px solid ${Colors.lightPrimary}`
        },
        // Border growth and dissapear
        '&:after': {
          borderBottom: `2px solid ${Colors.lightPrimary}`
        }
      }
    },
    MuiFormLabel: {
      root: {
        '&$error': {
          color: Colors.gray
        },
        '&$focused': {
          color: Colors.lightPrimary,
          '&$error': {
            color: Colors.errorRed
          }
        },
        color: Colors.gray,
        fontSize: FontSizes.medium
      }
    },
    MuiFormHelperText: {
      root: {
        fontSize: FontSizes.small
      }
    },
    MuiPaper: {
      root: {
        overflowX: 'auto'
      }
    },
    MuiCheckbox: {
      root: {
        padding: 12
      },
      colorSecondary: {
        '&:hover': { backgroundColor: Colors.flatLightPrimaryHover },
        '&$checked': {
          color: Colors.secondary,
          '&:hover': { backgroundColor: Colors.flatLightPrimaryHover }
        },
        '&$disabled': {
          color: Colors.disabledGray
        }
      }
    }
  }
};

export default { eui, mui };
