import React, { PureComponent } from 'react';
import { getIn } from 'seamless-immutable';
import { connect } from 'react-redux';
import { bool, string } from 'prop-types';
import { UTCard, UTLabel } from '@widergy/energy-ui';
import Fade from 'react-reveal/Fade';

import AccountActions from 'redux/account/actions';
import { ROUTES } from 'constants/routes';

import AccountSearch from './layout';
import { componentOptions } from './constants';
import styles from './styles.module.scss';

class AccountSearchContainer extends PureComponent {
  handleSubmit = value => {
    if (this.props.pathname === ROUTES.DIGITAL_BILL) {
      this.props.dispatch(AccountActions.getAccountEmail(value.account_number));
    } else {
      this.props.dispatch(AccountActions.getEmergencyRechargeInformation(value.prepaid_meter_number));
    }
  };

  render() {
    const { loading, accountNumber, pathname } = this.props;
    const options = componentOptions[pathname];
    return (
      <Fade>
        <UTCard className={styles.container}>
          <UTLabel bold title className={styles.text}>
            {options.title}
          </UTLabel>
          <UTLabel bold large className={styles.text}>
            {options.subtitle}
          </UTLabel>
          <AccountSearch
            onSubmit={this.handleSubmit}
            loading={loading}
            initialValues={{ account_number: accountNumber }}
            field={options.field}
          />
        </UTCard>
      </Fade>
    );
  }
}

AccountSearchContainer.propTypes = {
  pathname: string,
  loading: bool,
  accountNumber: string
};

const loadingSelector = store =>
  store.account.accountEmailLoading || store.account.emergencyRechargeInformationLoading;

const mapStateToProps = store => ({
  pathname: getIn(store, ['router', 'location', 'pathname']),
  loading: loadingSelector(store),
  accountNumber: store.account.accountNumber
});

export default connect(mapStateToProps)(AccountSearchContainer);
