import React, { useState, useMemo, Fragment } from 'react';
import { bool, number, func, string, arrayOf, any, shape } from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { isEmpty } from '@widergy/web-utils/lib/array';
import { UTLabel } from '@widergy/energy-ui';
import ScrollBar from 'react-perfect-scrollbar';

import CardError from 'app/components/CardError';
import GridWrapper from 'app/screens/Dashboard/screens/Turns/components/GridWrapper';
import { referenceType } from 'types/officeTypes';
import SearchBar from 'app/screens/Dashboard/components/GridSelector/SearchBar';

import OptionCard from './components/OptionCard';
import { filterOptions } from './utils';
import styles from './styles.module.scss';

const GridSelector = ({
  classes = {},
  currentValue,
  emptyMessage,
  error = false,
  gridSizeMD,
  gridSizeXS,
  handleSetValue,
  loading = false,
  options,
  reference,
  reload,
  searchPlaceholder,
  title,
  scrollable
}) => {
  const [filter, setFilter] = useState('');

  const filteredOptions = useMemo(() => filterOptions(options, filter), [options, filter]);

  const handleRenderOptions = item => (
    <OptionCard
      {...item}
      gridSizeMD={gridSizeMD}
      gridSizeXS={gridSizeXS}
      selected={item.value === currentValue}
      onClick={handleSetValue}
      classes={classes.optionCardsClasses}
    />
  );

  const Content = () =>
    scrollable ? (
      <ScrollBar
        options={{
          suppressScrollX: true,
          wheelPropagation: false
        }}
        className={classes.scrollbar}
      >
        <Grid spacing={3} container classes={{ root: classes.optionsContainer }}>
          <div className={classes.optionsContainer}>{filteredOptions.map(handleRenderOptions)}</div>
        </Grid>
      </ScrollBar>
    ) : (
      <div className={classes.optionsContainer}>{filteredOptions.map(handleRenderOptions)}</div>
    );

  return (
    <GridWrapper
      reference={reference}
      loading={loading}
      title={title}
      hasElements={!error}
      className={classes.gridWrapperContainer}
      titleClassName={classes.titleClassName}
    >
      <Fragment>
        {!isEmpty(options) && (
          <SearchBar filter={filter} setFilter={setFilter} placeholder={searchPlaceholder} />
        )}
        {isEmpty(filteredOptions) ? (
          <Grid item xs={12}>
            <UTLabel bold className={styles.zeroResultsMessage}>
              {emptyMessage}
            </UTLabel>
          </Grid>
        ) : (
          <Content />
        )}
      </Fragment>
      {error && <CardError error={error} buttonFunction={reload} />}
    </GridWrapper>
  );
};

GridSelector.propTypes = {
  classes: shape({
    optionsContainer: string
  }),
  currentValue: number,
  emptyMessage: string,
  error: string,
  gridSizeMD: number,
  gridSizeXS: number,
  handleSetValue: func.isRequired,
  loading: bool,
  options: arrayOf(any),
  reference: referenceType,
  reload: func,
  searchPlaceholder: string,
  scrollable: bool,
  title: string
};

export default GridSelector;
