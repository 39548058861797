import React, { PureComponent, Fragment } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { string, bool } from 'prop-types';
import { shouldShowErrors } from '@widergy/web-utils/lib/form';
import { UTLabel } from '@widergy/energy-ui';

import { inputPropTypes, metaPropTypes } from 'types/formTypes';

class UTCheckbox extends PureComponent {
  handleChange = (event, value) => this.props.input.onChange(value);

  render() {
    const { controlledCheck, input, label, labelClassName, meta, showError } = this.props;
    return (
      <Fragment>
        <FormControlLabel
          value={input.value}
          label={<UTLabel className={labelClassName}>{label}</UTLabel>}
          control={<Checkbox />}
          onChange={this.handleChange}
          checked={controlledCheck}
        />
        {shouldShowErrors(meta) && showError && (
          <UTLabel error small>
            {meta.error}
          </UTLabel>
        )}
      </Fragment>
    );
  }
}

UTCheckbox.propTypes = {
  controlledCheck: bool,
  input: inputPropTypes,
  label: string.isRequired,
  labelClassName: string,
  meta: metaPropTypes,
  showError: bool
};

export default UTCheckbox;
