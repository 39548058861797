import React, { Fragment } from 'react';
import { string, arrayOf, shape, node, bool } from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const Header = ({ columns }) => (
  <TableHead>
    <TableRow>
      {columns.map(column => (
        <TableCell key={column.key} align={column.align || 'left'} padding="default">
          <Fragment>{column.label}</Fragment>
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

Header.propTypes = {
  columns: arrayOf(
    shape({
      key: string.isRequired,
      label: string,
      cellRenderer: node,
      sortable: bool,
      sortKey: string,
      align: string
    })
  )
};

export default Header;
