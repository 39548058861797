import React, { memo } from 'react';
import { func, string } from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import { UTTextInput } from '@widergy/energy-ui';
import Grid from '@material-ui/core/Grid';

import styles from './styles.module.scss';

const SearchFilter = ({ filter, setFilter, placeholder }) => (
  <Grid item xs={12}>
    <div className={styles.searchRow}>
      <SearchIcon className={styles.searchIcon} />
      <UTTextInput
        input={{ value: filter, onChange: setFilter }}
        className={styles.textInput}
        inputClassName={styles.inputClassName}
        placeholder={placeholder}
        meta={{}}
      />
    </div>
  </Grid>
);

SearchFilter.propTypes = {
  filter: string,
  placeholder: string,
  setFilter: func
};

export default memo(SearchFilter);
