import api from 'config/api';

export default {
  getAccountEmail: async clientNumber => api.get(`admin_api/v1/accounts/${clientNumber}/contact_emails`),
  addEmail: async (clientNumber, emails, username, contactSource) =>
    api.post(`admin_api/v1/accounts/${clientNumber}/contact_emails`, {
      contact_emails: emails,
      ...(username && { username }),
      contact_source: contactSource
    }),
  editEmail: async (clientNumber, emails, username, contactSource) =>
    api.put(`admin_api/v1/accounts/${clientNumber}/contact_emails`, {
      contact_emails: emails,
      ...(username && { username }),
      contact_source: contactSource
    }),
  removeEmail: async (clientNumber, username, contactSource) =>
    api.delete(`admin_api/v1/accounts/${clientNumber}/contact_emails`, {
      username,
      contact_source: contactSource
    }),
  getEmergencyRechargeInformation: async prepaidMeterNumber =>
    api.get(`backoffice_api/v1/prepaids/emergency_charge?meter_prepaid_number=${prepaidMeterNumber}`),
  emergencyRecharge: async (prepaidMeterNumber, clientNumber, address) =>
    api.post('backoffice_api/v1/prepaids/emergency_charge', {
      client_number: clientNumber,
      meter_prepaid_number: prepaidMeterNumber,
      address
    }),
  getRechargeHistory: async clientNumber =>
    api.get(`backoffice_api/v1/payments/prepaid?client_number=${clientNumber}`)
};
