export default {
  es: {
    Commons: {
      accept: 'Aceptar',
      account: 'Cuenta',
      add: 'Adherir',
      adherence: 'Adherir',
      agreed: 'Entendido',
      back: 'Volver',
      cancel: 'Cancelar',
      clientNumber: 'NIS',
      close: 'Cerrar',
      delete: 'Eliminar',
      email: 'Email',
      history: 'Historial',
      modify: 'Modificar',
      password: 'Contraseña',
      refreshing: 'Usted cuenta con una versión desactualizada. La página se refrescará en breve',
      search: 'Buscar',
      signOut: 'Cerrar sesión',
      topbar: 'Backoffice'
    },
    Alerts: {
      claimTitle: '¡Gracias!',
      createNewClaim: 'Generar reclamo',
      defaultErrorTitle: 'Hubo un error',
      defaultTitle: 'Información',
      errorTitle: 'Hubo un error',
      paymentTitle: '¡Gracias por tu pago!',
      procedureTitle: '¡Listo!',
      rechargeTitle: '¡La recarga fue exitosa!'
    },
    DefaultMessages: {
      assignTurnFailure: 'Hubo un error al tomar el turno',
      assignTurnSuccess: 'El turno fue tomado correctamente',
      cancelTurnFailure: 'Hubo un error al cancelar el turno',
      cancelTurnSuccess: 'El turno fue cancelado correctamente',
      confirmTurnFailure: 'Hubo un error al confirmar el turno',
      confirmTurnSuccess: 'El turno fue confirmado correctamente',
      createTurnFailure: 'Hubo un error al crear el turno. Probá intentando de nuevo más tarde.',
      createTurnSuccess: 'El turno fue creado con éxito.',
      emergencyRechargeFailure: 'Hubo un error al realizar su recarga SOS',
      getOfficesFailure: 'Hubo un error al recuperar las oficinas',
      getOfficeWorkingDaysFailure: 'Hubo un error al recuperar los días de atención de la oficina',
      getOfficeWorkingDaySlotsFailure: 'Hubo un error al recuperar los horarios de atención',
      getRechargeHistoryFailure: 'Hubo un error al recuperar el historial de recargas',
      getTurnsFailure: 'Hubo un error al recuperar los turnos',
      getUserInformationFailure:
        'Hubo un error al recuperar los datos del usuario, por favor intente nuevamente más tarde',
      signInFailure: 'No se pudo iniciar sesión, el servicio no está disponible en este momento',
      unassignTurnFailure: 'Hubo un error al liberar el turno',
      unassignTurnSuccess: 'El turno fue liberado correctamente'
    },
    Validations: {
      clientNumberLengthValidation: 'El NIS debe ser de {{count}} caracteres',
      CUIT: 'El formato del CUIT/CUIL ingresado es inválido',
      emailConfirmationFailure: 'Los emails ingresados no coinciden.',
      emailRequired: 'El email es obligatorio',
      invalidEmail: 'El email ingresado no es válido',
      invalidFormat: 'Formato inválido',
      lastNameMaxLength: 'El apellido debe tener menos de 20 caracteres',
      nameMaxLength: 'El nombre tiene que tener menos de 20 caracteres',
      NIS: 'El NIS debe ser de 9 caracteres',
      passwordRequired: 'La contraseña es obligatoria',
      required: 'Campo obligatorio',
      sameEmail: 'El email debe ser distinto al actual.'
    },
    Maintenance: {
      maintenanceMessage:
        'Estamos realizando tareas de mantenimiento, por favor ingresá nuevamente más tarde',
      networkError:
        'Detectamos un problema en la conexión con el servidor. Por favor, intentá nuevamente más tarde.'
    },
    Forms: {
      cdi: 'Cédula de identidad',
      cdt: 'Certificado de documento en trámite',
      cip: 'CI provincial',
      clientNumberTitle: 'Número de suministro',
      contactSource: 'Canal',
      cuit: 'CUIT',
      cuitNumberTitle: 'CUIT/CUIL',
      dni: 'DNI',
      documentNumberPlaceholder: 'Número de documento',
      documentNumberTitle: 'Número de documento',
      documentTypePlaceholder: 'Tipo de documento',
      emailConfirmation: 'Confirmación de email',
      lastNameTitle: 'Apellido',
      lc: 'Libreta cívica',
      le: 'Libreta de enrolamiento',
      nameTitle: 'Nombre',
      other: 'Otros',
      passport: 'Pasaporte',
      phoneTitle: 'Teléfono',
      signInButton: 'Iniciar Sesión',
      signInTitle: 'Ingresá al backoffice'
    },
    Turns: {
      accept: 'Aceptar',
      assign: 'Tomar turno',
      assignedToMe: 'Tomaste este turno',
      assignedTurn: 'Tomados por mí',
      attentionHoursTitle: 'Seleccioná el horario',
      attentionType: 'Tipo de atención: ',
      buttonErrorCard: 'Recargar Formulario',
      calendarReferenceAvailable: 'Disponible',
      calendarReferenceNotAvailable: 'No disponible',
      calendarReferenceNotice: 'El calendario habilitará sólo los días con turnos disponibles.',
      calendarReferenceReminder: 'Recordá que no se puede pedir turno para el mismo día.',
      calendarReferenceSelected: 'Seleccionado',
      calendarReferenceTitle: 'Referencias:',
      calendarTitle: 'Seleccioná la fecha',
      cancelDialogContent: '¿Está seguro de cancelar el turno?',
      cancelDialogReason: '¿Cuál es el motivo de la cancelación?',
      cancelDialogTitle: 'Cancelación de turno',
      cancelEditionContactInformation: 'Cancelar',
      cancelledTurn: 'Turno cancelado',
      clientNumber: 'NIS:',
      confirmDialogContent: '¿Está seguro de confirmar el turno?',
      confirmDialogTitle: 'Confirmación de turno',
      confirmedTurn: 'Turno confirmado',
      confirmTurnButton: 'Confirmar turno',
      contactInformationEditionSubmit: 'Aplicar cambios',
      contactInformationSubmit: 'Siguiente',
      contactInformationTitle: 'Datos de contacto',
      date: 'Fecha y hora:',
      editContactInformation: 'Editar campos',
      email: 'Email:',
      emptyOffices: 'No cuenta con oficinas por el momento',
      filterBy: 'Filtrar por',
      firstName: 'Nombre:',
      lastName: 'Apellido:',
      lastOperationBy: '**Última actividad por:**',
      notAssignedToMe: 'Turno tomado',
      notSpecified: 'No especificado',
      office: 'Oficina:',
      officesTitle: 'Seleccioná la oficina',
      pendingTurn: 'Turno pendiente',
      phone: 'Tel:',
      reloadCalendar: 'Recargar calendario',
      searchAttentionTypePlaceholder: 'Buscar tipo de atención',
      searchAttentionTypeZeroResults: 'No se encontraron tipos de atención',
      searchButton: 'Buscar',
      searchByDNI: 'Buscar por DNI',
      searchOfficesPlaceholder: 'Buscar oficina comercial',
      searchOfficesZeroResults: 'No se encontraron oficinas',
      searchTurnsTitle: 'Buscar turnos por',
      selectAttentionType: 'Seleccioná el tipo de atención',
      selectDateTitle: 'Seleccioná la fecha',
      selectOfficeTitle: 'Seleccioná la oficina',
      selectProcedurePlaceHolder: 'Seleccionar trámite',
      selectProcedureTitle: 'Seleccioná el trámite a realizar',
      selectTimeTitle: 'Seleccioná el horario',
      singleAttentionTypeInfo: 'Solo se encuentra disponible la atención de tipo **{{value}}**.',
      statusabsent: 'Estado: Ausente',
      statuscanceled: 'Estado: Cancelado',
      statusconfirmed: 'Estado: Confirmado',
      statusnot_attended: 'Estado: Turno no efectivo',
      statuspending: 'Estado: Pendiente',
      summaryDetailAttentionType: 'Tipo de atención: **{{type}}**',
      summaryDetailDate: '{{date}}',
      summaryDetailDocument: '{{documentType}}',
      summaryDetailEmail: 'Email:',
      summaryDetailName: 'Nombre:',
      summaryDetailNis: 'NIS:',
      summaryDetailOffice: '{{officeName}}',
      summaryDetailPhone: 'Teléfono:',
      summaryDetailProcedure: 'Trámite:',
      summaryDetails: 'Datos del turno',
      summaryDetailsSubtitle: 'Presioná la opción que desea modificar.',
      summaryDetailsTitle: 'Detalles del turno seleccionado',
      summaryTitle: 'Confirmá el turno',
      summaryUserDetails: 'Datos del cliente',
      tabCreateTurns: 'Alta de turnos',
      tabManageTurns: 'Gestión de turnos',
      takenBy: '**Tomado por:**',
      takenByMeAclaration: '(yo)',
      title: 'Gestión de turnos',
      titleErrorCard: 'Ups! Hubo un problema',
      turnsByDayZeroResults: 'No se encontraron turnos en la fecha seleccionada',
      turnsByDocumentZeroResults: 'No hay turnos pendientes para el documento ingresado',
      turnsListTitle: 'Confirmá o cancelá turnos',
      unassign: 'Liberar turno',
      unassignDialogContent:
        'Una vez desasignado el turno otros agentes tendrán permisos para su asignación y edición.',
      unassignDialogTitle: 'Liberar turno',
      userInformation: 'Info del cliente',
      withoutTurns: 'No hay turnos disponibles'
    },
    PhoneInput: {
      areaCodeLabel: 'Cod. área',
      areaCodePlaceholder: 'Sin 0',
      codeWithZero: 'Ingrese el código sin 0',
      invalidCode: 'Código de área invalido',
      phonePlaceholder: 'Ingresá tu teléfono',
      phoneWithFifteen: 'El número no debe tener 15'
    }
  }
};
