import React from 'react';
import { UTLabel, UTLoading } from '@widergy/energy-ui';
import { string, bool, number } from 'prop-types';
import Grid from '@material-ui/core/Grid';

import { referenceType } from 'types/officeTypes';

import styles from './styles.module.scss';

const GridWrapper = ({
  title,
  withGrid,
  children,
  loading,
  loadingClassName,
  className,
  titleClassName,
  loadingSize,
  reference
}) => (
  <div className={`${styles.generalContainer} ${className}`}>
    <span className={styles.anchor} ref={reference} />
    <UTLabel bold large className={`${styles.title} ${titleClassName}`}>
      {title}
    </UTLabel>
    <UTLoading loading={loading} className={loadingClassName} size={loadingSize}>
      <div>
        {withGrid ? (
          <Grid spacing={3} container>
            {children}
          </Grid>
        ) : (
          children
        )}
      </div>
    </UTLoading>
  </div>
);

GridWrapper.propTypes = {
  title: string,
  withGrid: bool,
  loading: bool,
  className: string,
  loadingClassName: string,
  loadingSize: number,
  titleClassName: string,
  reference: referenceType
};

export default GridWrapper;
