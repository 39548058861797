import React, { useEffect, useRef } from 'react';
import i18n from 'i18next';
import { connect } from 'react-redux';
import { getIn } from 'seamless-immutable';
import { bool, shape, string } from 'prop-types';
import { UTLabel } from '@widergy/energy-ui';

import { officesType, referenceType, valuesType, workingDaySlotType } from 'types/officeTypes';
import GridWrapper from 'app/screens/Dashboard/screens/Turns/components/GridWrapper';

import { handleScrollTo } from '../../utils';

import styles from './styles.module.scss';
import Details from './components/Details';
import { buildClientDetails, buildTurnDetails } from './utils';

const Summary = ({ offices, officeWorkingDaySlots, refs, turnForms, values }) => {
  const clientDetails = buildClientDetails(values, refs);
  const turnDetails = buildTurnDetails(values, offices, officeWorkingDaySlots, turnForms, refs);

  const reference = useRef(null);

  useEffect(() => {
    handleScrollTo(reference);
  }, [values.selectedTime]);

  return (
    <GridWrapper
      reference={reference}
      title={i18n.t('Turns:summaryTitle')}
      className={styles.cardWrapper}
      titleClassName={styles.title}
    >
      <div className={styles.content}>
        <div className={styles.titlesSection}>
          <UTLabel semibold large white className={styles.detailsTitle}>
            {i18n.t('Turns:summaryDetailsTitle')}
          </UTLabel>
          <UTLabel white className={styles.detailsSubtitle}>
            {i18n.t('Turns:summaryDetailsSubtitle')}
          </UTLabel>
        </div>
        <div>
          <Details title={i18n.t('Turns:summaryUserDetails')} detailsList={clientDetails} />
          <Details title={i18n.t('Turns:summaryDetails')} detailsList={turnDetails} />
        </div>
      </div>
    </GridWrapper>
  );
};

Summary.propTypes = {
  offices: officesType,
  officeWorkingDaySlots: workingDaySlotType,
  turnForms: shape({
    form_ov: bool,
    label: string,
    value: string
  }),
  refs: shape({
    procedure: referenceType,
    contactInformation: referenceType,
    office: referenceType,
    date: referenceType,
    time: referenceType
  }),
  values: valuesType
};

const mapStateToProps = store => ({
  offices: store.office.offices,
  officeWorkingDaySlots: store.office.officeWorkingDaySlots,
  turnForms: getIn(store, ['utility', 'configuration', 'turn_forms'])
});

export default connect(mapStateToProps)(Summary);
