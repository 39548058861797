import { AlertHandler } from '@widergy/energy-ui';
import i18n from 'i18next';
import { onlyNumbers } from '@widergy/web-utils/lib/normalize';

import { MANAGE_TURNS } from 'constants/routes';

export const addDashClientNumber = value =>
  !value || value.length <= 2
    ? onlyNumbers(value)
    : `${onlyNumbers(value).slice(0, 2)}-${onlyNumbers(value).slice(2, 9)}`;

export default {
  alertDialog: {
    dialogProps: {
      whiteTitle: true
    },
    textProps: {
      white: true
    }
  },
  dialogButton: {
    accept: {
      text: true
    },
    cancel: {
      text: true,
      gray: true
    }
  },
  dialogLabel: {
    primary: true
  },
  topbar: {
    link: '',
    withLogo: true,
    withLogout: true,
    withLabel: false,
    withLogOutCustomIcon: true
  },
  footer: {
    enabled: false
  },
  turns: {
    clientNumberRequired: false,
    createTurnsEnabled: true,
    withAssignment: true,
    withLastActivity: true
  },
  forms: {
    clientNumberLength: 9,
    nameMaxLength: 999,
    lastNameMaxLength: 999,
    phoneLength: 11,
    phoneFieldWithAreaCode: true,
    clientNumberNormalizer: addDashClientNumber,
    clientNumberPatternValidation: /^[0-9]{1,2}-[0-9]{1,7}$/,
    docTypes: {
      dni: true,
      cuit: true,
      provincialCi: false,
      identityCard: false,
      enlistmentBook: false,
      passport: false,
      civicNotebook: false
    }
  },
  buttonRadius: '5px',
  fontFamily: 'Edea',
  defaultRoute: MANAGE_TURNS,
  contactInformation: {
    emailRequired: false
  },
  applicationRefresh: {
    url: `${window.origin}/static/js/`,
    forceReload: true,
    withAlertAction: true,
    alertDispatcher: () => AlertHandler.error(i18n.t('Commons:refreshing')),
    pollingTime: 3600000
  },
  offices: {
    officeTag: {
      withOfficeCity: false
    }
  },
  customerInfo: {
    nameInfo: {
      enabled: true
    }
  }
};
