import React, { memo, useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { bool, string, func, number } from 'prop-types';
import i18 from 'i18next';
import { UTLabel, UTIconButton, UTButton } from '@widergy/energy-ui';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';

import { ReactComponent as Assign } from 'app/assets/turns/assign-icon.svg';
import { ReactComponent as Unassign } from 'app/assets/turns/unassign-icon.svg';
import OfficeActions from 'redux/office/actions';
import { officesType, turnType } from 'types/officeTypes';
import { TURN_STATUS } from 'constants/turns';
import appConfig from 'config/appConfig';

import { CONFIRM, CANCEL, UNASSIGN } from '../../constants';

import styles from './styles.module.scss';
import { turnLabelsInformation } from './constants';

const Turn = ({
  dispatch,
  turnData,
  className,
  showOffice,
  afterConfirm,
  afterCancel,
  offices,
  userId,
  turnForTransitionId
}) => {
  const [aceptDialogIsOpen, setAceptDialogIsOpen] = useState(false);
  const [cancelDialogIsOpen, setCancelDialogIsOpen] = useState(false);
  const [unassignDialogIsOpen, setUnassignDialogIsOpen] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [hovered, setHovered] = useState(false);
  const { withAssignment } = appConfig.turns;
  const { nameInfo } = appConfig.customerInfo;
  const assignedTo = turnData.assigned_to;

  const onMouseEnter = () => setHovered(true);
  const onMouseLeave = () => setHovered(false);

  const confirmTurn = () => {
    setAceptDialogIsOpen(true);
    dispatch(OfficeActions.setTurnForTransition(turnData.id, CONFIRM, afterConfirm));
  };

  const cancelTurn = () => {
    setCancelDialogIsOpen(true);
    dispatch(OfficeActions.setTurnForTransition(turnData.id, CANCEL, afterCancel));
  };

  const unassignTurn = () => {
    setUnassignDialogIsOpen(true);
    dispatch(OfficeActions.setTurnForTransition(turnData.id, UNASSIGN));
  };

  const assignTurn = () => {
    const officeId = offices.find(office => office.name === turnData.office_name).id;
    const dayId = turnData.working_day_slot.working_day_id;
    if (!officeId || !dayId) return;
    const successCallback = () => dispatch(OfficeActions.getTurnsByDay(officeId, dayId));
    dispatch(OfficeActions.updateAssignment(turnData.id, successCallback));
  };

  useEffect(() => {
    if (!turnForTransitionId && (aceptDialogIsOpen || cancelDialogIsOpen || unassignDialogIsOpen)) {
      setAceptDialogIsOpen(false);
      setCancelDialogIsOpen(false);
      setUnassignDialogIsOpen(false);
    }
  }, [turnForTransitionId]);

  const handleInfo = () => setOpenInfo(!openInfo);
  const statusIsPending = turnData.status === TURN_STATUS.PENDING;
  const canUpdateStatus = statusIsPending && (!assignedTo || (assignedTo && assignedTo.id === userId));

  const AssignmentButton = !assignedTo ? (
    <UTButton className={styles.assignButton} onPress={assignTurn} outlined>
      <Assign />
      {i18.t('Turns:assign')}
    </UTButton>
  ) : assignedTo.id === userId ? (
    <div
      className={styles.assignedToMeButtonContainer}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <UTButton
        className={`${styles.assignedToMeButton} ${hovered && styles.assignedToMeButtonHovered}`}
        onPress={unassignTurn}
      >
        {hovered ? <Unassign /> : <Assign />}
        {i18.t(`Turns:${hovered ? 'unassign' : 'assignedToMe'}`)}
      </UTButton>
    </div>
  ) : (
    <UTButton className={styles.notAssignedToMeButton}>
      <Assign />
      {i18.t('Turns:notAssignedToMe')}
    </UTButton>
  );

  const turnInfo = turnLabelsInformation(turnData, userId, showOffice);

  return (
    <div className={`${styles.turn} ${className}`}>
      <div className={styles.containerTurn}>
        <div className={styles.turnText}>
          {turnData.procedure_name && (
            <UTLabel gray className={styles.procedureNameLabel}>
              {turnData.procedure_name}
            </UTLabel>
          )}
          {turnInfo.map(
            label =>
              label.shouldShow && (
                <div className={styles.labelContainer} key={label.labelText}>
                  <UTLabel gray className={styles.label}>
                    {label.labelText}
                  </UTLabel>
                  <UTLabel gray className={styles.value}>
                    {label.valueText}
                  </UTLabel>
                </div>
              )
          )}
          <div className={styles.statusLabels}>
            <UTLabel semibold className={styles[`${turnData.status}Turn`]}>
              {i18.t(`Turns:status${turnData.status}`)}
            </UTLabel>
            {withAssignment && statusIsPending && AssignmentButton}
          </div>
        </div>
        {canUpdateStatus && (
          <div className={styles.turnButtons}>
            <UTIconButton className={styles.iconButton} onClick={confirmTurn}>
              <DoneIcon className={aceptDialogIsOpen ? styles.selectedConfirmTurn : styles.confirmTurn} />
            </UTIconButton>
            <UTIconButton className={styles.iconButton} onClick={cancelTurn}>
              <CloseIcon className={cancelDialogIsOpen ? styles.selectedCancelTurn : styles.cancelTurn} />
            </UTIconButton>
          </div>
        )}
      </div>
      <div className={styles.divider} />
      <div className={styles.containerUserInfo}>
        <div className={styles.containerDocumentName}>
          {turnData.user_document_type && turnData.user_document_number && (
            <UTLabel gray className={styles.documentLabel}>
              {`**${turnData.user_document_type.toUpperCase()}:** ${turnData.user_document_number}`}
            </UTLabel>
          )}
          {turnData.user_first_name && turnData.user_last_name && (
            <UTLabel gray bold className={styles.nameLabel}>
              {`${turnData.user_first_name} ${turnData.user_last_name}`}
            </UTLabel>
          )}
        </div>
        <div className={styles.containerArrow}>
          <UTLabel className={styles.userInformation} gray>
            {i18.t('Turns:userInformation')}
          </UTLabel>
          <UTIconButton className={styles.iconButton} onClick={handleInfo}>
            <KeyboardArrowDown className={`${styles.arrowSize} ${openInfo && styles.arrowRotate}`} />
          </UTIconButton>
        </div>
      </div>
      {openInfo && (
        <Fragment>
          <div className={styles.containerPhoneEmail}>
            <UTLabel gray>{`**${i18.t('Turns:phone')}**  ${turnData.phone ||
              i18.t('Turns:notSpecified')}`}</UTLabel>
            <UTLabel gray className={styles.emailLabel}>
              {`**${i18.t('Turns:email')}** ${turnData.email || i18.t('Turns:notSpecified')}`}
            </UTLabel>
          </div>
          {nameInfo.enabled && (
            <div className={styles.fullNameContainer}>
              <UTLabel gray>{`**${i18.t('Turns:firstName')}**  ${turnData.first_name ||
                i18.t('Turns:notSpecified')}`}</UTLabel>
              <UTLabel gray className={styles.lastNameLabel}>
                {`**${i18.t('Turns:lastName')}** ${turnData.last_name || i18.t('Turns:notSpecified')}`}
              </UTLabel>
            </div>
          )}

          <UTLabel gray className={styles.nisLabel}>
            {`**${i18.t('Turns:clientNumber')}** ${turnData.client_number || i18.t('Turns:notSpecified')} `}
          </UTLabel>
        </Fragment>
      )}
    </div>
  );
};

Turn.propTypes = {
  afterCancel: func,
  afterConfirm: func,
  className: string,
  offices: officesType,
  showOffice: bool,
  turnData: turnType,
  turnForTransitionId: string,
  userId: number
};

const mapStateToProps = store => ({
  offices: store.office.offices,
  turnForTransitionId: store.office.turnForTransition.turnId,
  userId: store.user.currentUser?.id
});

export default connect(mapStateToProps)(memo(Turn));
