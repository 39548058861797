import { createTypes, completeTypes } from 'redux-recompose';
import i18 from 'i18next';
import { AlertHandler } from '@widergy/energy-ui';
import { retrieveErrors } from '@widergy/web-utils/lib/message';

import UserService from 'services/UserServices';

/* ------------- Auth actions ------------- */
export const actions = createTypes(completeTypes(['GET_USER_INFORMATION'], ['REMOVE_USER']), '@@USER');

const privateActionCreators = {
  getUserInformationSuccess: payload => ({
    type: actions.GET_USER_INFORMATION_SUCCESS,
    target: 'currentUser',
    payload
  }),
  getUserInformationFailure: () => ({
    type: actions.GET_USER_INFORMATION_FAILURE,
    target: 'currentUser'
  })
};

export const actionCreators = {
  getUserInformation: () => async dispatch => {
    dispatch({ type: actions.GET_USER_INFORMATION, target: 'currentUser' });
    const response = await UserService.getUserInformation();
    if (response.ok) {
      dispatch(privateActionCreators.getUserInformationSuccess(response.data));
    } else {
      dispatch(privateActionCreators.getUserInformationFailure());
      AlertHandler.error(retrieveErrors(response.error, i18.t('DefaultMessages:getUserInformationFailure')));
    }
  },
  removeUser: origin => async dispatch => dispatch({ type: actions.REMOVE_USER, payload: origin })
};

export default actionCreators;
