import { createSelector } from 'reselect';

import { TURN_STATUS } from 'constants/turns';

const filterCondition = (filterValue, userId) =>
  ({
    [TURN_STATUS.ASSIGNED]: item => item.assigned_to?.id === userId
  }[filterValue] || (item => item.status === filterValue));

export const turnsSelector = createSelector(
  store => store.office.turnsByDay,
  store => store.user.currentUser?.id,
  (_, props) => props.filterValue,
  (turnsByDay, userId, filterValue) => {
    const filterConditionFunc = filterCondition(filterValue, userId);
    return !filterValue ? turnsByDay : turnsByDay?.filter(item => filterConditionFunc(item));
  }
);
