import React from 'react';
import { connect } from 'react-redux';
import { string, shape, bool } from 'prop-types';
import { getIn } from 'seamless-immutable';
import { UTLabel } from '@widergy/energy-ui';
import Error from '@material-ui/icons/Error';
import { push } from 'connected-react-router';

import AccountActions from 'redux/account/actions';

import { handleTabOptions } from './constants';
import Home from './layout';
import styles from './styles.module.scss';

const HomeContainer = ({ dispatch, pathname, initError, backofficeVisibleTabs }) => {
  const handleTabChange = value => {
    dispatch(AccountActions.cleanData());
    dispatch(push(value));
  };
  const tabOptions = handleTabOptions(backofficeVisibleTabs);
  return (
    <div className={styles.generalContainer}>
      {!initError ? (
        <Home tabOptions={tabOptions} selectedTab={pathname} onTabChange={handleTabChange} />
      ) : (
        <div className={styles.maintenance}>
          <Error className={styles.icon} />
          <UTLabel bold xxlarge gray>
            {initError}
          </UTLabel>
        </div>
      )}
    </div>
  );
};

HomeContainer.propTypes = {
  pathname: string,
  initError: string,
  backofficeVisibleTabs: shape({
    show_digital_bill: bool,
    show_recharge_sos: bool
  })
};

const mapStateToProps = store => ({
  pathname: getIn(store, ['router', 'location', 'pathname']),
  initError: store.auth.initError,
  backofficeVisibleTabs: getIn(store, ['utility', 'configuration', 'backoffice_visible_tabs'], {})
});

export default connect(mapStateToProps)(HomeContainer);
