import { createTypes, completeTypes } from 'redux-recompose';
import i18 from 'i18next';
import { retrieveErrors, retrieveSuccessMessage } from '@widergy/web-utils/lib/message';
import { AlertHandler } from '@widergy/energy-ui';
import Polling from '@widergy/polling-handling';

import OfficeService from 'services/OfficeService';
import { TURN_STATUS } from 'constants/turns';
import { CUIT } from 'constants/fields';

import { getAvailableSlots } from './utils';

export const actions = createTypes(
  completeTypes(
    [
      'GET_OFFICES',
      'GET_OFFICE_WORKING_DAYS',
      'GET_OFFICE_WORKING_DAY_SLOTS',
      'GET_TURNS_BY_DAY',
      'GET_TURNS_BY_DOCUMENT',
      'UPDATE_TURN_STATUS',
      'UPDATE_ASSIGNMENT',
      'CLEAN_ASSIGNMENT',
      'CANCEL_ABSTENT_TURN',
      'CANCEL_NOT_ATTENDED_TURN',
      'CREATE_TURN'
    ],
    [
      'CLEAN_OFFICE_DATA',
      'CLEAN_TURNS_BY_DAY',
      'CLEAN_OFFICE_SLOTS',
      'CLEAN_TURNS_BY_DOCUMENT',
      'SET_TURN_FOR_TRANSITION'
    ]
  ),
  '@@CONFIG'
);

const privateActionCreators = {
  getOfficesTurnsSuccess: payload => ({
    type: actions.GET_OFFICES_SUCCESS,
    target: 'offices',
    payload
  }),
  getOfficesTurnsFailure: error => ({
    type: actions.GET_OFFICES_FAILURE,
    target: 'offices',
    payload: error
  }),
  getOfficeWorkingDaysSuccess: payload => ({
    type: actions.GET_OFFICE_WORKING_DAYS_SUCCESS,
    target: 'officeWorkingDays',
    payload
  }),
  getOfficeWorkingDaysFailure: error => ({
    type: actions.GET_OFFICE_WORKING_DAYS_FAILURE,
    target: 'officeWorkingDays',
    payload: error
  }),
  getOfficeWorkingDaySlotsSuccess: payload => ({
    type: actions.GET_OFFICE_WORKING_DAY_SLOTS_SUCCESS,
    target: 'officeWorkingDaySlots',
    payload
  }),
  getOfficeWorkingDaySlotsFailure: error => ({
    type: actions.GET_OFFICE_WORKING_DAY_SLOTS_FAILURE,
    target: 'officeWorkingDaySlots',
    payload: error
  }),
  getTurnsByDaySuccess: payload => ({
    type: actions.GET_TURNS_BY_DAY_SUCCESS,
    target: 'turnsByDay',
    payload
  }),
  getTurnsByDayFailure: error => ({
    type: actions.GET_TURNS_BY_DAY_FAILURE,
    target: 'turnsByDay',
    payload: error
  }),
  getTurnsByDocumentSuccess: payload => ({
    type: actions.GET_TURNS_BY_DOCUMENT_SUCCESS,
    target: 'turnsByDocument',
    payload
  }),
  getTurnsByDocumentFailure: error => ({
    type: actions.GET_TURNS_BY_DOCUMENT_FAILURE,
    target: 'turnsByDocument',
    payload: error
  }),
  updateTurnStatusSuccess: payload => ({
    type: actions.UPDATE_TURN_STATUS_SUCCESS,
    target: 'updateTurnStatus',
    payload
  }),
  updateTurnStatusFailure: error => ({
    type: actions.UPDATE_TURN_STATUS_FAILURE,
    target: 'updateTurnStatus',
    payload: error
  }),
  updateAssignmentSuccess: payload => ({
    type: actions.UPDATE_ASSIGNMENT_SUCCESS,
    target: 'updateAssignment',
    payload
  }),
  updateAssignmentFailure: error => ({
    type: actions.UPDATE_ASSIGNMENT_FAILURE,
    target: 'updateAssignment',
    payload: error
  }),
  cleanAssignmentSuccess: payload => ({
    type: actions.CLEAN_ASSIGNMENT_SUCCESS,
    target: 'cleanAssignment',
    payload
  }),
  cleanAssignmentFailure: error => ({
    type: actions.CLEAN_ASSIGNMENT_FAILURE,
    target: 'cleanAssignment',
    payload: error
  }),
  createTurnSuccess: payload => ({
    type: actions.CREATE_TURN_SUCCESS,
    target: 'createTurn',
    payload
  }),
  createTurnFailure: error => ({
    type: actions.CREATE_TURN_FAILURE,
    target: 'createTurn',
    payload: error
  })
};

export const actionCreators = {
  getOffices: clientNumber => async dispatch => {
    dispatch({ type: actions.GET_OFFICES, target: `offices` });
    const response = await Polling.handle(OfficeService.getOffices(clientNumber));
    if (response.error) {
      const error = retrieveErrors(response.data, i18.t('DefaultMessages:getOfficesFailure'));
      dispatch(privateActionCreators.getOfficesTurnsFailure(error));
    } else {
      dispatch(privateActionCreators.getOfficesTurnsSuccess(response.data));
    }
  },

  getOfficeWorkingDays: (office, month, year, sucessCallback) => async dispatch => {
    dispatch({ type: actions.GET_OFFICE_WORKING_DAYS, target: 'officeWorkingDays' });
    const response = await OfficeService.getOfficeWorkingDays(office, month, year);
    if (response.ok) {
      if (sucessCallback) sucessCallback();
      dispatch(privateActionCreators.getOfficeWorkingDaysSuccess(response.data));
    } else {
      const error = retrieveErrors(response.data, i18.t('DefaultMessages:getOfficeWorkingDaysFailure'));
      dispatch(privateActionCreators.getOfficeWorkingDaysFailure(error));
    }
  },

  getOfficeWorkingDaySlots: (office, day) => async dispatch => {
    dispatch({ type: actions.GET_OFFICE_WORKING_DAY_SLOTS, target: 'officeWorkingDaySlots' });
    const response = await OfficeService.getOfficeWorkingDaySlots(office, day);
    if (response.ok) {
      dispatch(
        privateActionCreators.getOfficeWorkingDaySlotsSuccess(
          getAvailableSlots(response.data.working_day_slots)
        )
      );
    } else {
      const error = retrieveErrors(response.data, i18.t('DefaultMessages:getOfficeWorkingDaySlotsFailure'));
      dispatch(privateActionCreators.getOfficeWorkingDaySlotsFailure(error));
    }
  },

  getTurnsByDay: (office, day) => async dispatch => {
    dispatch({ type: actions.GET_TURNS_BY_DAY, target: 'turnsByDay' });
    const response = await OfficeService.getTurnsByDay(office, day);
    if (response.ok) {
      dispatch(privateActionCreators.getTurnsByDaySuccess(response.data));
    } else {
      const error = retrieveErrors(response.data, i18.t('DefaultMessages:getTurnsFailure'));
      dispatch(privateActionCreators.getTurnsByDayFailure(error));
    }
  },

  getTurnsByDocument: (documentNumber, documentType) => async dispatch => {
    dispatch({ type: actions.GET_TURNS_BY_DOCUMENT, target: 'turnsByDocument' });
    const response = await OfficeService.getTurnsByDocument(documentNumber, documentType);
    if (response.ok) {
      dispatch(privateActionCreators.getTurnsByDocumentSuccess(response.data));
    } else {
      const error = retrieveErrors(response.data, i18.t('DefaultMessages:getTurnsFailure'));
      dispatch(privateActionCreators.getTurnsByDocumentFailure(error));
    }
  },

  updateTurnStatus: (turnId, successCallback, status, statusReason) => async dispatch => {
    dispatch({ type: actions.UPDATE_TURN_STATUS, target: 'updateTurnStatus', payload: turnId });
    const response = await OfficeService.updateTurnStatus(turnId, status, statusReason);
    if (response.ok) {
      dispatch(privateActionCreators.updateTurnStatusSuccess({ turnId, status }));
      AlertHandler.success(
        status === TURN_STATUS.CONFIRMED
          ? i18.t('DefaultMessages:confirmTurnSuccess')
          : i18.t('DefaultMessages:cancelTurnSuccess')
      );
      if (successCallback) successCallback();
    } else {
      const error = retrieveErrors(
        response.data,
        status === TURN_STATUS.CONFIRMED
          ? i18.t('DefaultMessages:confirmTurnFailure')
          : i18.t('DefaultMessages:cancelTurnFailure')
      );
      dispatch(privateActionCreators.updateTurnStatusFailure(error));
      AlertHandler.error(error);
    }
  },

  updateAssignment: (turnId, successCallback) => async dispatch => {
    dispatch({ type: actions.UPDATE_ASSIGNMENT, target: 'updateAssignment', payload: turnId });
    const response = await OfficeService.updateAssignment(turnId);
    if (response.ok) {
      dispatch(privateActionCreators.updateAssignmentSuccess({ turnId }));
      AlertHandler.success(i18.t('DefaultMessages:assignTurnSuccess')); // TEXTOS A CAMBIAR
      if (successCallback) successCallback();
    } else {
      const error = retrieveErrors(response.data, i18.t('DefaultMessages:assignTurnFailure'));
      dispatch(privateActionCreators.updateAssignmentFailure(error));
      AlertHandler.error(error);
    }
  },

  cleanAssignment: (turnId, successCallback) => async dispatch => {
    dispatch({ type: actions.CLEAN_ASSIGNMENT, target: 'cleanAssignment', payload: turnId });
    const response = await OfficeService.cleanAssignment(turnId);
    if (response.ok) {
      dispatch(privateActionCreators.cleanAssignmentSuccess({ turnId }));
      AlertHandler.success(i18.t('DefaultMessages:unassignTurnSuccess'));
      if (successCallback) successCallback();
    } else {
      const error = retrieveErrors(response.data, i18.t('DefaultMessages:unassignTurnFailure'));
      dispatch(privateActionCreators.cleanAssignmentFailure(error));
      AlertHandler.error(error);
    }
  },

  createTurn: (slot, procedure, contactInformation, attentionType, successCallback) => async dispatch => {
    dispatch({ type: actions.CREATE_TURN, target: 'createTurn' });

    const cleanContactInformation = {
      ...contactInformation,
      document_number:
        contactInformation.document_type === CUIT
          ? contactInformation.cuit_number
          : contactInformation.document_number
    };

    delete cleanContactInformation.cuit_number;

    const response = await OfficeService.createTurn(slot, procedure, cleanContactInformation, attentionType);

    if (response.ok) {
      const messageSuccess = retrieveSuccessMessage(
        response.data,
        i18.t('DefaultMessages:createTurnSuccess')
      );
      dispatch(privateActionCreators.createTurnSuccess(messageSuccess));
      if (successCallback) successCallback();
    } else {
      const error = retrieveErrors(response.data, i18.t('DefaultMessages:createTurnFailure'));
      dispatch(privateActionCreators.createTurnFailure(error));
      AlertHandler.error(error);
    }
  },

  cleanOfficeData: () => dispatch => dispatch({ type: actions.CLEAN_OFFICE_DATA }),
  cleanTurnsByDay: () => dispatch => dispatch({ type: actions.CLEAN_TURNS_BY_DAY }),
  cleanOfficeSlots: () => dispatch => dispatch({ type: actions.CLEAN_OFFICE_SLOTS }),
  cleanTurnsByDocument: () => dispatch => dispatch({ type: actions.CLEAN_TURNS_BY_DOCUMENT }),
  setTurnForTransition: (turnId, action, afterAction) => dispatch =>
    dispatch({ type: actions.SET_TURN_FOR_TRANSITION, payload: { turnId, action, afterAction } })
};

export default actionCreators;
