import i18n from 'i18next';

import { TURN_STATUS } from 'constants/turns';

export const filterOptions = [
  { label: i18n.t('Turns:confirmedTurn'), value: TURN_STATUS.CONFIRMED },
  { label: i18n.t('Turns:cancelledTurn'), value: TURN_STATUS.CANCELED },
  { label: i18n.t('Turns:pendingTurn'), value: TURN_STATUS.PENDING },
  { label: i18n.t('Turns:assignedTurn'), value: TURN_STATUS.ASSIGNED }
];
