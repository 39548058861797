import React, { useState } from 'react';
import { UTButton } from '@widergy/energy-ui';
import { func, arrayOf, string } from 'prop-types';

import styles from './styles.module.scss';

const OptionSelector = ({ options, getSelectedOption }) => {
  const [selectedOption, setSelectedOption] = useState();

  const setOption = key => () => {
    setSelectedOption(key);
    getSelectedOption({ action: key, fromSelector: true });
  };

  return (
    <div className={styles.container}>
      {options.map(({ key, label }) => (
        <UTButton text disabled={key === selectedOption} onPress={setOption(key)} className={styles.option}>
          {label}
        </UTButton>
      ))}
    </div>
  );
};

OptionSelector.propTypes = {
  options: arrayOf(string),
  getSelectedOption: func
};

export default OptionSelector;
