export const SET_CONTACT_INFORMATION = 'SET_CONTACT_INFORMATION';
export const SET_SELECTED_DATE = 'SET_SELECTED_DATE';
export const SET_SELECTED_OFFICE = 'SET_SELECTED_OFFICE';
export const SET_SELECTED_PROCEDURE = 'SET_SELECTED_PROCEDURE';
export const SET_SELECTED_TIME = 'SET_SELECTED_TIME';
export const SET_RESULT_DIALOG_OPEN = 'SET_RESULT_DIALOG_OPEN';
export const SET_SELECTED_ATTENTION_TYPE = 'SET_SELECTED_ATTENTION_TYPE';

export const initialState = {
  contactInformation: null,
  selectedDate: null,
  selectedOffice: null,
  selectedProcedure: null,
  selectedTime: null,
  selectedAttentionType: undefined,
  resultDialogOpen: false
};

export const reducer = (state, action) => {
  switch (action.type) {
    case SET_CONTACT_INFORMATION:
      return { ...state, contactInformation: action.payload };
    case SET_SELECTED_DATE:
      return { ...state, selectedDate: action.payload };
    case SET_SELECTED_OFFICE:
      return { ...state, selectedOffice: action.payload };
    case SET_SELECTED_PROCEDURE:
      return { ...state, selectedProcedure: action.payload };
    case SET_SELECTED_TIME:
      return { ...state, selectedTime: action.payload };
    case SET_RESULT_DIALOG_OPEN:
      return { ...state, resultDialogOpen: action.payload };
    case SET_SELECTED_ATTENTION_TYPE:
      return { ...state, selectedAttentionType: action.payload };
    default:
      return state;
  }
};

export const actionsCreator = dispatch => ({
  handleContactInformation: payload => dispatch({ type: SET_CONTACT_INFORMATION, payload }),
  handleSelectedDate: payload => dispatch({ type: SET_SELECTED_DATE, payload }),
  handleSelectedOffice: payload => dispatch({ type: SET_SELECTED_OFFICE, payload }),
  handleSelectedProcedure: payload => dispatch({ type: SET_SELECTED_PROCEDURE, payload }),
  handleSelectedTime: payload => dispatch({ type: SET_SELECTED_TIME, payload }),
  handleSelectedAttentionType: payload => dispatch({ type: SET_SELECTED_ATTENTION_TYPE, payload }),
  handleOpenResultDialog: () => dispatch({ type: SET_RESULT_DIALOG_OPEN, payload: true }),
  handleCloseResultDialog: () => dispatch({ type: SET_RESULT_DIALOG_OPEN, payload: false })
});
