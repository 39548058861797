import React, { useEffect, useState } from 'react';
import Rollbar from 'rollbar';
import ReactGA from 'react-ga';
import { disableReactDevTools } from '@widergy/web-utils/lib/config';
import { EnergyThemeProvider, AlertHandler, UTAlert } from '@widergy/energy-ui';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { StylesProvider } from '@material-ui/styles';
import JssProvider from 'react-jss/lib/JssProvider';
import { create } from 'jss';
import jssPreset from 'jss-preset-default';
import { shape, string, object } from 'prop-types';
import { connect } from 'react-redux';
import Polling from '@widergy/polling-handling';
import { useWithRefreshVersion } from '@widergy/energy-hooks';

import AuthActions from 'redux/auth/actions';
import api, { apiSetup } from 'config/api';
import { PUBLIC_ROUTES } from 'constants/routes';
import appConfig from 'config/appConfig';

import Routes from './components/Routes';
import UTAlertDialog from './components/UTAlertDialog';
import { energyUITheme, muiTheme } from './styles';

const jss = create(jssPreset());

jss.options.insertionPoint = document.getElementById('jss-insertion-point');

const { applicationRefresh } = appConfig;

const AppContainer = ({ dispatch, location }) => {
  const [alert, setAlert] = useState();

  useEffect(() => {
    AlertHandler.init({ setState: setAlert });
    Rollbar.init({
      accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
      captureUncaught: true,
      captureUnhandledRejections: true,
      hostWhiteList: ['widergy.com', 'widergydev.com'],
      payload: {
        environment: process.env.REACT_APP_UTILITY_NAME,
        client: {
          javascript: {
            source_map_enabled: true,
            code_version: `backoffice-${process.env.REACT_APP_UTILITY_NAME}-${process.env.REACT_APP_ENV}`
          }
        }
      },
      enabled: process.env.NODE_ENV !== 'development'
    });
    window.Rollbar = Rollbar;
    apiSetup();
    ReactGA.set({ userId: 1 });
    disableReactDevTools(process.env.REACT_APP_ENV);
    Polling.init(`${process.env.REACT_APP_API_BASE_URL}async_request/jobs/`, api.monitors);
    const isPublicRoute = PUBLIC_ROUTES.includes(location.pathname);
    if (!isPublicRoute) dispatch(AuthActions.setEnteredUrl(location));
    dispatch(AuthActions.init(isPublicRoute));
  }, []);

  useWithRefreshVersion(applicationRefresh);

  return (
    <JssProvider jss={jss}>
      <StylesProvider injectFirst>
        <MuiThemeProvider theme={muiTheme}>
          <EnergyThemeProvider theme={energyUITheme}>
            <UTAlert content={alert} />
            <UTAlertDialog />
            <Routes />
          </EnergyThemeProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </JssProvider>
  );
};

AppContainer.propTypes = {
  location: shape({
    pathname: string.isRequired,
    state: object,
    search: string
  })
};

const mapStateToProps = store => ({
  location: store.router.location
});

export default connect(mapStateToProps)(AppContainer);
