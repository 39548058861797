import i18 from 'i18next';

import HistoryActions from 'redux/history/actions';
import { HISTORY_ADHERENCE, HISTORY_SEND_BILL } from 'constants/routes';

export const TAB_OPTIONS = [
  {
    value: HISTORY_ADHERENCE,
    label: i18.t('History:adherence')
  },
  {
    value: HISTORY_SEND_BILL,
    label: i18.t('History:sendBills')
  }
];

const COLUMNS_ADHERENCE = [
  {
    key: 'action',
    label: i18.t('History:action')
  },
  {
    key: 'prevEmail',
    label: i18.t('History:prevEmail')
  },
  {
    key: 'email',
    label: i18.t('History:email')
  },
  {
    key: 'channel',
    label: i18.t('History:channel')
  },
  {
    key: 'username',
    label: i18.t('History:username')
  },
  {
    key: 'contactSource',
    label: i18.t('History:contactSource')
  },
  {
    key: 'date',
    label: i18.t('History:date')
  }
];

const COLUMNS_SEND_BILL = [
  {
    key: 'status',
    label: i18.t('History:status')
  },
  {
    key: 'email',
    label: i18.t('Commons:email')
  },
  {
    key: 'sendIntents',
    label: i18.t('History:sendIntents')
  },
  {
    key: 'billId',
    label: i18.t('History:billId')
  },
  {
    key: 'date',
    label: i18.t('History:date')
  }
];

export const CONTENT = {
  [HISTORY_ADHERENCE]: {
    columns: COLUMNS_ADHERENCE,
    action: HistoryActions.getHistoryAdherence,
    cleanAction: HistoryActions.cleanHistoryAdherence
  },
  [HISTORY_SEND_BILL]: {
    columns: COLUMNS_SEND_BILL,
    action: HistoryActions.getHistorySendBills,
    cleanAction: HistoryActions.cleanHistorySendBills
  }
};
