import React, { memo } from 'react';

import { useScreenSize } from 'utils/hooks/useScreenSize';

import UTMobileTable from './components/MobileTable';
import UTDesktopTable from './components/DesktopTable';

const UTTable = props => {
  const { isMobileOrTablet } = useScreenSize();

  const Component = isMobileOrTablet ? UTMobileTable : UTDesktopTable;
  return <Component {...props} />;
};

export default memo(UTTable);
