import { createTypes, completeTypes } from 'redux-recompose';
import i18 from 'i18next';
import { AlertHandler } from '@widergy/energy-ui';
import { retrieveErrors } from '@widergy/web-utils/lib/message';
import Polling from '@widergy/polling-handling';

import HistoryService from 'services/HistoryService';

import { historySendBillsSelector, historyAdherenceSelector } from './selectors';

/* ------------- History actions ------------- */
export const actions = createTypes(
  completeTypes(
    ['GET_HISTORY_ADHERENCE', 'GET_HISTORY_SEND_BILLS'],
    ['CLEAN_HISTORY_ADHERENCE', 'CLEAN_HISTORY_SEND_BILLS']
  ),
  '@@HISTORY'
);

const privateActionCreators = {
  getHistoryAdherenceSuccess: payload => ({
    type: actions.GET_HISTORY_ADHERENCE_SUCCESS,
    target: 'historyAdherence',
    payload
  }),

  getHistoryAdherenceFailure: error => ({
    type: actions.GET_HISTORY_ADHERENCE_FAILURE,
    target: 'historyAdherence',
    payload: error
  }),

  getHistorySendBillsSuccess: (history, address) => ({
    type: actions.GET_HISTORY_SEND_BILLS_SUCCESS,
    target: 'historySendBills',
    payload: {
      history,
      address
    }
  }),

  getHistorySendBillsFailure: error => ({
    type: actions.GET_HISTORY_SEND_BILLS_FAILURE,
    target: 'historySendBills',
    payload: error
  })
};

export const actionCreators = {
  getHistoryAdherence: accountNumber => async dispatch => {
    dispatch({ type: actions.GET_HISTORY_ADHERENCE, target: 'historyAdherence' });
    const response = await Polling.handle(HistoryService.getHistoryAdherence(accountNumber));
    if (response.error) {
      const error = retrieveErrors(response.data, i18.t('DefaultMessages:getHistoryAdherenceFailure'));
      AlertHandler.error(error);
      dispatch(privateActionCreators.getHistoryAdherenceFailure(error));
    } else {
      dispatch(privateActionCreators.getHistoryAdherenceSuccess(historyAdherenceSelector(response.data)));
    }
  },
  getHistorySendBills: accountNumber => async dispatch => {
    dispatch({ type: actions.GET_HISTORY_SEND_BILLS, target: 'historySendBills' });
    const response = await Polling.handle(HistoryService.getHistorySendBills(accountNumber));
    if (response.error) {
      const error = retrieveErrors(response.data, i18.t('DefaultMessages:getHistorySendBillsFailure'));
      AlertHandler.error(error);
      dispatch(privateActionCreators.getHistorySendBillsFailure(error));
    } else {
      dispatch(
        privateActionCreators.getHistorySendBillsSuccess(
          historySendBillsSelector(response.data.account_bill_emails),
          response.data.account_address
        )
      );
    }
  },
  cleanHistoryAdherence: () => async dispatch => dispatch({ type: actions.CLEAN_HISTORY_ADHERENCE }),
  cleanHistorySendBills: () => async dispatch => dispatch({ type: actions.CLEAN_HISTORY_SEND_BILLS })
};

export default actionCreators;
