import api from 'config/api';

export default {
  getOffices: clientNumber => api.get(`api/v1/turns/offices`, { client_number: clientNumber }),
  getOfficeWorkingDays: (office, month, year) =>
    api.get(`backoffice_api/v1/offices/${office}/working_days`, { month, year }),
  getOfficeWorkingDaySlots: (office, day) =>
    api.get(`backoffice_api/v1/offices/${office}/working_days/${day}`),
  getTurnsByDay: (office, day) => api.get(`backoffice_api/v1/offices/${office}/working_days/${day}/turns`),
  getTurnsByDocument: (documentNumber, documentType = 'dni') =>
    api.get(`backoffice_api/v1/turns`, {
      document_number: documentNumber,
      document_type: documentType
    }),
  updateTurnStatus: (turnId, status, statusReason) =>
    api.put(`backoffice_api/v1/turns/${turnId}/status`, {
      status,
      status_reason: statusReason
    }),
  updateAssignment: turnId => api.post(`backoffice_api/v1/turns/${turnId}/assignment`),
  cleanAssignment: turnId => api.delete(`backoffice_api/v1/turns/${turnId}/assignment`),
  createTurn: (slot, procedure, contactInformation, attentionType) =>
    api.post(`backoffice_api/v1/turns`, {
      working_day_slot_id: slot,
      procedure_name: procedure,
      attention_type: attentionType,
      ...contactInformation
    })
};
