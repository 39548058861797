/* eslint-disable no-alert */
import { createTypes, completeTypes } from 'redux-recompose';
import { AlertHandler } from '@widergy/energy-ui';
import { retrieveSuccessMessage, retrieveErrors } from '@widergy/web-utils/lib/message';
import i18 from 'i18next';
import { push } from 'connected-react-router';
import Polling from '@widergy/polling-handling';
import { reset } from 'redux-form';

import AccountService from 'services/AccountService';
import AlertDialogActions from 'redux/alertDialog/actions';
import { INFO, ERROR } from 'app/components/UTAlertDialog/utils';
import { ACCOUNT_SEARCH } from 'constants/reduxForms';
import { ROUTES } from 'constants/routes';

/* ------------- Account actions ------------- */
export const actions = createTypes(
  completeTypes(
    [
      'GET_ACCOUNT_EMAIL',
      'ADD_EMAIL',
      'EDIT_EMAIL',
      'REMOVE_EMAIL',
      'GET_EMERGENCY_RECHARGE_INFORMATION',
      'EMERGENCY_RECHARGE',
      'GET_RECHARGE_HISTORY'
    ],
    ['SET_ACCOUNT_NUMBER', 'CLEAN_DATA']
  ),
  '@@ACCOUNT'
);

const privateActionCreators = {
  getAccountEmailSuccess: payload => ({
    type: actions.GET_ACCOUNT_EMAIL_SUCCESS,
    target: 'accountEmail',
    payload
  }),
  getAccountEmailFailure: error => ({
    type: actions.GET_ACCOUNT_EMAIL_FAILURE,
    target: 'accountEmail',
    payload: error
  }),
  addEmailSuccess: () => ({
    type: actions.ADD_EMAIL_SUCCESS,
    target: 'addEmail'
  }),
  addEmailFailure: error => ({
    type: actions.ADD_EMAIL_FAILURE,
    target: 'addEmail',
    payload: error
  }),
  editEmailSuccess: payload => ({
    type: actions.EDIT_EMAIL_SUCCESS,
    target: 'editEmail',
    payload
  }),
  editEmailFailure: error => ({
    type: actions.EDIT_EMAIL_FAILURE,
    target: 'editEmail',
    payload: error
  }),
  removeEmailSuccess: payload => ({
    type: actions.REMOVE_EMAIL_SUCCESS,
    target: 'removeEmail',
    payload
  }),
  removeEmailFailure: error => ({
    type: actions.REMOVE_EMAIL_FAILURE,
    target: 'removeEmail',
    payload: error
  }),
  getEmergencyRechargeInformationSuccess: payload => ({
    type: actions.GET_EMERGENCY_RECHARGE_INFORMATION_SUCCESS,
    target: 'emergencyRechargeInformation',
    payload
  }),
  getEmergencyRechargeInformationFailure: error => ({
    type: actions.GET_EMERGENCY_RECHARGE_INFORMATION_FAILURE,
    target: 'emergencyRechargeInformation',
    payload: error
  }),
  emergencyRechargeSuccess: payload => ({
    type: actions.EMERGENCY_RECHARGE_SUCCESS,
    target: 'emergencyRecharge',
    payload
  }),
  emergencyRechargeFailure: error => ({
    type: actions.EMERGENCY_RECHARGE_FAILURE,
    target: 'emergencyRecharge',
    payload: error
  }),
  getRechargeHistorySuccess: payload => ({
    type: actions.GET_RECHARGE_HISTORY_SUCCESS,
    target: 'rechargeHistory',
    payload
  }),
  getRechargeHistoryFailure: error => ({
    type: actions.GET_RECHARGE_HISTORY_FAILURE,
    target: 'rechargeHistory',
    payload: error
  })
};

export const actionCreators = {
  getAccountEmail: accountNumber => async dispatch => {
    dispatch({ type: actions.SET_ACCOUNT_NUMBER, target: 'accountNumber', payload: accountNumber });
    dispatch({ type: actions.GET_ACCOUNT_EMAIL, target: 'accountEmail' });
    const response = await Polling.handle(AccountService.getAccountEmail(accountNumber));
    if (response.error) {
      AlertHandler.error(retrieveErrors(response.data, i18.t('DefaultMessages:getAccountEmailFailure')));
      dispatch(privateActionCreators.getAccountEmailFailure(response.error));
    } else {
      dispatch(privateActionCreators.getAccountEmailSuccess(response.data));
    }
  },
  addEmail: params => async (dispatch, getState) => {
    dispatch({ type: actions.ADD_EMAIL, target: 'addEmail' });
    const { emails, contactSource, clientNumber } = params;
    const { username } = getState().auth;
    const response = await Polling.handle(
      AccountService.addEmail(clientNumber, emails, username, contactSource)
    );
    if (response.error) {
      AlertHandler.error(retrieveErrors(response.data, i18.t('DefaultMessages:addEmailFailure')));
      dispatch(privateActionCreators.addEmailFailure(response.error));
    } else {
      AlertHandler.success(retrieveSuccessMessage(response.data, i18.t('DefaultMessages:addEmailSuccess')));
      dispatch(actionCreators.getAccountEmail(clientNumber));
      dispatch(privateActionCreators.addEmailSuccess());
    }
  },
  editEmail: params => async (dispatch, getState) => {
    dispatch({ type: actions.EDIT_EMAIL, target: 'editEmail' });
    const { emails, contactSource, clientNumber } = params;
    const { username } = getState().auth;
    const response = await Polling.handle(
      AccountService.editEmail(clientNumber, emails, username, contactSource)
    );
    if (response.error) {
      AlertHandler.error(retrieveErrors(response.data, i18.t('DefaultMessages:editEmailFailure')));
      dispatch(privateActionCreators.editEmailFailure(response.error));
    } else {
      AlertHandler.success(retrieveSuccessMessage(response.data, i18.t('DefaultMessages:editEmailSuccess')));
      dispatch(actionCreators.getAccountEmail(clientNumber));
      dispatch(privateActionCreators.editEmailSuccess());
    }
  },
  removeEmail: params => async (dispatch, getState) => {
    dispatch({ type: actions.REMOVE_EMAIL, target: 'removeEmail' });
    const { clientNumber, contactSource } = params;
    const { username } = getState().auth;
    const response = await Polling.handle(AccountService.removeEmail(clientNumber, username, contactSource));
    if (response.error) {
      AlertHandler.error(retrieveErrors(response.data, i18.t('DefaultMessages:removeEmailFailure')));
      dispatch(privateActionCreators.removeEmailFailure(response.error));
    } else {
      AlertHandler.success(
        retrieveSuccessMessage(response.data, i18.t('DefaultMessages:removeEmailSuccess'))
      );
      dispatch(actionCreators.getAccountEmail(clientNumber));
      dispatch(privateActionCreators.removeEmailSuccess());
    }
  },
  cleanData: () => dispatch => {
    dispatch({ type: actions.CLEAN_DATA });
  },
  getEmergencyRechargeInformation: prepaidMeterNumber => async dispatch => {
    dispatch({ type: actions.GET_EMERGENCY_RECHARGE_INFORMATION, target: 'emergencyRechargeInformation' });
    dispatch({ type: actions.CLEAN_DATA });
    const response = await Polling.handle(AccountService.getEmergencyRechargeInformation(prepaidMeterNumber));
    if (response.error) {
      AlertHandler.error(retrieveErrors(response.error, i18.t('DefaultMessages:getPrepaidMeterFailure')));
      dispatch(privateActionCreators.getEmergencyRechargeInformationFailure(response.error));
    } else {
      dispatch(
        privateActionCreators.getEmergencyRechargeInformationSuccess({
          ...response.data,
          prepaidMeterNumber
        })
      );
    }
  },
  emergencyRecharge: (prepaidMeterNumber, clientNumber, address) => async dispatch => {
    dispatch({ type: actions.EMERGENCY_RECHARGE, target: 'emergencyRecharge' });
    const response = await Polling.handle(
      AccountService.emergencyRecharge(prepaidMeterNumber, clientNumber, address)
    );
    if (response.error) {
      dispatch(privateActionCreators.emergencyRechargeFailure(response.error));
      dispatch(
        AlertDialogActions.openAlertDialog({
          contentType: ERROR,
          content: retrieveErrors(response.data, i18.t('DefaultMessages:emergencyRechargeFailure'))
        })
      );
    } else {
      dispatch(privateActionCreators.emergencyRechargeSuccess(response.data));
      dispatch(
        AlertDialogActions.openAlertDialog({
          title: i18.t('Alerts:rechargeTitle'),
          contentType: INFO,
          content: response.data && response.data.message,
          onDismiss: () => {
            dispatch({ type: actions.CLEAN_DATA });
            dispatch(reset(ACCOUNT_SEARCH));
          }
        })
      );
    }
  },
  getRechargeHistory: clientNumber => async dispatch => {
    dispatch({ type: actions.GET_RECHARGE_HISTORY, target: 'rechargeHistory' });
    const response = await Polling.handle(AccountService.getRechargeHistory(clientNumber));
    if (response.error) {
      AlertHandler.error(retrieveErrors(response.data, i18.t('DefaultMessages:getRechargeHistoryFailure')));
      dispatch(privateActionCreators.getRechargeHistoryFailure(response.error));
      dispatch(push(ROUTES.EMERGENCY_RECHARGE));
    } else {
      dispatch(privateActionCreators.getRechargeHistorySuccess(response.data));
    }
  }
};

export default actionCreators;
