import React, { useEffect } from 'react';
import { arrayOf, bool, func, string } from 'prop-types';
import i18n from 'i18next';
import { UTLabel } from '@widergy/energy-ui';

import { referenceType } from 'types/officeTypes';
import GridSelector from 'app/screens/Dashboard/components/GridSelector';

import { handleScrollTo } from '../../utils';

import { optionsMapper } from './utils';
import styles from './styles.module.scss';

const SelectAttentionTypes = ({
  currentAttentionType,
  onChange,
  attentionTypes,
  attentionTypesError,
  attentionTypesLoading,
  refAttentionTypes,
  reload
}) => {
  useEffect(() => {
    if (attentionTypes?.length === 1) onChange(attentionTypes[0]);
  }, [attentionTypes?.length]);

  useEffect(() => {
    handleScrollTo(refAttentionTypes);
  }, []);

  return attentionTypes?.length === 1 ? (
    <div className={styles.cardContainer}>
      <UTLabel secondary className={styles.alertText}>
        {i18n.t('Turns:singleAttentionTypeInfo', { value: attentionTypes[0].toUpperCase() })}
      </UTLabel>
    </div>
  ) : (
    <GridSelector
      classes={{
        optionsContainer: styles.gridContainer,
        gridWrapperContainer: styles.container,
        scrollbar: styles.scrollbar,
        titleClassName: styles.title,
        optionCardsClasses: {
          root: styles.gridCard,
          labelContainer: styles.optionCardLabel,
          name: styles.optionCardLabelName
        }
      }}
      currentValue={currentAttentionType}
      emptyMessage={i18n.t('Turns:searchAttentionTypeZeroResults')}
      error={attentionTypesError}
      handleSetValue={onChange}
      loading={attentionTypesLoading}
      options={!attentionTypesLoading && optionsMapper(attentionTypes)}
      reference={refAttentionTypes}
      reload={reload}
      searchPlaceholder={i18n.t('Turns:searchAttentionTypePlaceholder')}
      title={i18n.t('Turns:selectAttentionType')}
    />
  );
};

SelectAttentionTypes.propTypes = {
  attentionTypes: arrayOf(string),
  attentionTypesError: string,
  attentionTypesLoading: bool,
  refAttentionTypes: referenceType,
  currentAttentionType: string,
  onChange: func.isRequired,
  reload: func
};

export default SelectAttentionTypes;
