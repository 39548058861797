import React from 'react';
import { UTCard, UTLabel, UTTouchableWithoutFeedback } from '@widergy/energy-ui';
import Grid from '@material-ui/core/Grid';
import { string, func, bool, number, shape } from 'prop-types';

import { ReactComponent as Done } from 'app/assets/turns/done.svg';

import styles from './styles.module.scss';

const OptionCard = ({
  name,
  description,
  value,
  selected,
  onClick,
  gridSizeMD = 3,
  gridSizeXS = 12,
  classes = {}
}) => (
  <Grid item xs={gridSizeXS} md={gridSizeMD} classes={{ root: classes.root }}>
    <UTTouchableWithoutFeedback
      className={`${styles.generalContainer} ${classes.card}`}
      onClick={() => onClick(value)}
    >
      <UTCard className={`${styles.card} ${selected && styles.cardSelect}`} flat>
        <div className={`${styles.radio} ${selected && styles.radioSelect}`}>{selected && <Done />}</div>
        <div className={`${styles.labelContainer} ${classes.labelContainer}`}>
          <UTLabel
            className={`${styles.nameLabel} ${selected && styles.labelSelected} ${classes.name}`}
            darkGray
          >
            **{name}**
          </UTLabel>
          <UTLabel
            className={`${styles.descriptionLabel} ${selected && styles.labelSelected} ${
              classes.description
            }`}
            darkGray
          >
            {description}
          </UTLabel>
        </div>
      </UTCard>
    </UTTouchableWithoutFeedback>
  </Grid>
);

OptionCard.propTypes = {
  classes: shape(string),
  name: string,
  description: string,
  gridSizeMD: number,
  gridSizeXS: number,
  value: number,
  selected: bool,
  onClick: func
};

export default OptionCard;
