import React from 'react';
import i18 from 'i18next';
import { func, bool } from 'prop-types';
import { reduxForm } from 'redux-form';
import { handleKeyDown } from '@widergy/utilitygo-forms-web';
import { UTLoading, UTLabel, UTButton } from '@widergy/energy-ui';

import { UTILITIES } from 'config/common';
import UTForm from 'app/components/UTForm';
import authLogo from 'app/assets/auth-logo.png';
import { emailField, passwordField } from 'constants/fields';
import { SIGN_IN_FORM } from 'constants/reduxForms';

import styles from './styles.module.scss';

const fields = [{ ...emailField }, passwordField];

const buttonProps = {
  [UTILITIES.EDELAP]: { outlined: true },
  [UTILITIES.EDENOR]: { outlined: true },
  [UTILITIES.EDEN]: {},
  [UTILITIES.EDESA]: {},
  [UTILITIES.EDEA]: {}
};

const SignIn = ({ handleSubmit, submitFailed, invalid, loading }) => (
  <div className={styles.container}>
    <div className={styles.signInForm}>
      <div className={styles.logoContainer}>
        <img src={authLogo} alt="" className={styles.authlogo} />
      </div>
      <UTLabel large className={styles.title}>
        {i18.t('Forms:signInTitle')}
      </UTLabel>
      <UTLoading loading={loading}>
        <form onSubmit={handleSubmit} onKeyDown={handleKeyDown(handleSubmit)}>
          <UTForm fields={fields} />
          <UTButton
            disabled={submitFailed && invalid}
            uppercase
            className={styles.submitButton}
            type="submit"
            {...buttonProps[process.env.REACT_APP_UTILITY_NAME]}
          >
            {i18.t('Forms:signInButton')}
          </UTButton>
        </form>
      </UTLoading>
    </div>
  </div>
);

SignIn.propTypes = {
  handleSubmit: func,
  submitFailed: bool,
  invalid: bool,
  loading: bool
};

export default reduxForm({ form: SIGN_IN_FORM })(SignIn);
