import i18 from 'i18next';

import appConfig from 'config/appConfig';
import { ROUTES } from 'constants/routes';

export const tabOptions = [
  {
    value: ROUTES.MANAGE_TURNS,
    label: i18.t('Turns:tabManageTurns'),
    show: true
  },
  {
    value: ROUTES.CREATE_TURNS,
    label: i18.t('Turns:tabCreateTurns'),
    show: appConfig.turns.createTurnsEnabled
  }
].filter(tab => tab.show);
