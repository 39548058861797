import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bool, shape, object, string } from 'prop-types';
import { getURLParams } from '@widergy/web-utils/lib/browser';

import AuthActions from 'redux/auth/actions';
import * as LocalStorageService from 'services/LocalStorageService';

import SignIn from './layout';

const SignInContainer = ({ dispatch, loading, location }) => {
  const [externalUsername, setExternalUsername] = useState(null);

  useEffect(() => {
    const { email, password, username } =
      location.search && getURLParams(location.search, 'email', 'password', 'username');
    if (username) {
      setExternalUsername(username);
      dispatch(AuthActions.setUsername(username));
    }
    if (email && password) {
      const currentSessionToken = LocalStorageService.getSessionToken();
      if (currentSessionToken) dispatch(AuthActions.signOut());
      dispatch(AuthActions.signIn({ email, password, external_username: username }, true));
    }
  }, [dispatch, location]);

  const handleSignIn = values =>
    dispatch(AuthActions.signIn({ ...values, external_username: externalUsername }));

  return <SignIn onSubmit={handleSignIn} loading={loading} />;
};

SignInContainer.propTypes = {
  loading: bool,
  location: shape({
    pathname: string.isRequired,
    state: object,
    search: string
  })
};

const mapStateToProps = store => ({
  loading: store.auth.sessionLoading || store.auth.signInLoading || store.user.currentUserLoading,
  location: store.router.location
});

export default connect(mapStateToProps)(SignInContainer);
