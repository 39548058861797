import { createTypes, completeTypes } from 'redux-recompose';
import { push } from 'connected-react-router';
import Polling from '@widergy/polling-handling';
import i18 from 'i18next';
import { getIn } from 'seamless-immutable';

import UtilityService from 'services/UtilityService';
import { ROUTES } from 'constants/routes';
import { CONFIG_TIMEOUT, DEFAULT_RETRIES } from 'config/api';

export const actions = createTypes(
  completeTypes(['HEALTH_CHECK', 'GET_CONFIG', 'UTILITY_HEALTH_CHECK']),
  '@@CONFIG'
);

const privateActionCreators = {
  getConfigSuccess: result => ({
    type: actions.GET_CONFIG_SUCCESS,
    payload: result,
    target: 'configuration'
  }),
  getConfigFailure: error => ({
    type: actions.GET_CONFIG_FAILURE,
    payload: error,
    target: 'configuration'
  }),
  healthCheckSuccess: payload => ({
    type: actions.HEALTH_CHECK_SUCCESS,
    payload,
    target: 'healthCheck'
  }),
  healthCheckFailure: payload => ({
    type: actions.HEALTH_CHECK_FAILURE,
    payload,
    target: 'healthCheck'
  }),
  utilityHealthCheckSuccess: payload => ({
    type: actions.UTILITY_HEALTH_CHECK_SUCCESS,
    payload,
    target: 'healthCheck'
  }),
  utilityHealthCheckFailure: payload => ({
    type: actions.UTILITY_HEALTH_CHECK_FAILURE,
    payload,
    target: 'healthCheck'
  })
};

export const actionCreators = {
  getConfig: () => async dispatch => {
    dispatch({ type: actions.GET_CONFIG, target: 'configuration' });
    const response = await Polling.handle(UtilityService.getConfig(), CONFIG_TIMEOUT, {
      retries: DEFAULT_RETRIES
    });
    if (response.error) {
      dispatch(privateActionCreators.getConfigFailure(response.error));
      dispatch(push(ROUTES.MAINTENANCE));
    } else {
      dispatch(privateActionCreators.getConfigSuccess(response.data));
    }
  },
  healthCheck: () => async dispatch => {
    dispatch({ type: actions.HEALTH_CHECK, target: 'healthCheck' });
    const response = await UtilityService.healthCheck();
    if (response.ok) {
      if (response.data && response.data.healthy) await dispatch(actionCreators.getConfig());
      dispatch(privateActionCreators.healthCheckSuccess(response.data));
      if (!response.data.healthy) dispatch(push(ROUTES.MAINTENANCE));
    } else {
      dispatch(privateActionCreators.healthCheckFailure(response.problem));
      dispatch(push(ROUTES.MAINTENANCE));
    }
  },
  utilityHealthCheck: () => async dispatch => {
    dispatch({ type: actions.HEALTH_CHECK, target: 'healthCheck' });
    const response = await UtilityService.utilityHealthCheck();
    if (response.ok) {
      dispatch(privateActionCreators.utilityHealthCheckSuccess());
    } else {
      dispatch(
        privateActionCreators.utilityHealthCheckFailure(
          getIn(response, ['data', 'error'], i18.t('Maintenance:maintenanceMessage'))
        )
      );
      dispatch(push(ROUTES.MAINTENANCE));
    }
  }
};

export default actionCreators;
