import React from 'react';
import { string } from 'prop-types';
import { connect } from 'react-redux';
import { getIn } from 'seamless-immutable';
import { Route, Switch } from 'react-router-dom';
import { UTTabs } from '@widergy/energy-ui';
import { push } from 'connected-react-router';

import appConfig from 'config/appConfig';
import { ROUTES } from 'constants/routes';
import OfficeActions from 'redux/office/actions';

import ManageTurns from './screens/ManageTurns';
import CreateTurns from './screens/CreateTurns';
import styles from './styles.module.scss';
import { tabOptions } from './constants';

const Turns = ({ dispatch, pathname }) => {
  const onTabChange = value => {
    dispatch(OfficeActions.cleanOfficeData());
    dispatch(OfficeActions.cleanOfficeSlots());
    dispatch(push(value));
  };

  return (
    <div className={styles.generalContainer}>
      <div className={styles.tabsContainer}>
        <UTTabs
          value={pathname}
          options={tabOptions}
          onChange={onTabChange}
          tabsProps={{ variant: 'standard', orientation: 'horizontal' }}
          classes={{
            tabRoot: styles.tab,
            indicator: styles.indicator,
            selected: styles.tabSelected,
            label: styles.tabLabel
          }}
        />
      </div>
      <div className={styles.contentContainer}>
        <Switch>
          {appConfig.turns.createTurnsEnabled && (
            <Route exact path={ROUTES.CREATE_TURNS} component={CreateTurns} />
          )}
          <Route path={ROUTES.MANAGE_TURNS} component={ManageTurns} />
        </Switch>
      </div>
    </div>
  );
};

Turns.propTypes = {
  pathname: string
};

const mapStateToProps = store => ({
  pathname: getIn(store, ['router', 'location', 'pathname'])
});

export default connect(mapStateToProps)(Turns);
