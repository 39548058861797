import React, { useEffect, useState } from 'react';
import i18 from 'i18next';
import { isEmpty } from '@widergy/web-utils/lib/array';
import { connect } from 'react-redux';
import { arrayOf, bool, func, instanceOf, string } from 'prop-types';
import { UTLoading } from '@widergy/energy-ui';

import { referenceType, valuesType, workingDaySlotType } from 'types/officeTypes';
import OfficeActions from 'redux/office/actions';
import CardError from 'app/components/CardError';
import GridWrapper from 'app/screens/Dashboard/screens/Turns/components/GridWrapper';

import { handleScrollTo } from '../../utils';
import { cardErrorClasses } from '../../constants';

import SlotsList from './components/SlotsList';
import styles from './styles.module.scss';

const SelectTime = ({
  dispatch,
  onChange,
  officeWorkingDays,
  officeWorkingDaySlots,
  officeWorkingDaySlotsError,
  officeWorkingDaySlotsLoading,
  reference,
  values
}) => {
  const [mounted, setMounted] = useState(false);

  const getSlots = () => {
    const daySelected = officeWorkingDays.find(item => item.day === values.selectedDate.getDate());
    if (daySelected && daySelected.available_turns) {
      dispatch(OfficeActions.getOfficeWorkingDaySlots(values.selectedOffice, daySelected.id));
    } else if (!isEmpty(officeWorkingDaySlots)) {
      dispatch(OfficeActions.cleanOfficeSlots());
    }
  };

  useEffect(() => {
    if (mounted) handleScrollTo(reference);
    else setMounted(true);

    if (values.selectedDate && officeWorkingDays) getSlots();

    return () => onChange(null);
  }, [values.selectedDate]);

  return (
    <GridWrapper
      reference={reference}
      title={i18.t('Turns:selectTimeTitle')}
      className={styles.gridWrapper}
      titleClassName={styles.gridTitle}
    >
      <UTLoading loading={officeWorkingDaySlotsLoading}>
        {officeWorkingDaySlotsError ? (
          <CardError
            error={officeWorkingDaySlotsError}
            buttonFunction={getSlots}
            textButton={i18.t('Turns:reloadCalendar')}
            classes={cardErrorClasses}
          />
        ) : isEmpty(officeWorkingDaySlots) ? (
          <CardError title={i18.t('Turns:withoutTurns')} classes={cardErrorClasses} />
        ) : (
          <SlotsList
            officeWorkingDaySlots={officeWorkingDaySlots}
            selectSlot={onChange}
            selectedValue={values.selectedTime}
          />
        )}
      </UTLoading>
    </GridWrapper>
  );
};

SelectTime.propTypes = {
  onChange: func,
  officeWorkingDays: arrayOf(instanceOf(Date)),
  officeWorkingDaySlots: workingDaySlotType,
  officeWorkingDaySlotsError: string,
  officeWorkingDaySlotsLoading: bool,
  reference: referenceType,
  values: valuesType
};

const mapStateToProps = store => ({
  officeWorkingDays: store.office.officeWorkingDays,
  officeWorkingDaySlots: store.office.officeWorkingDaySlots,
  officeWorkingDaySlotsError: store.office.officeWorkingDaySlotsError,
  officeWorkingDaySlotsLoading: store.office.officeWorkingDaySlotsLoading
});

export default connect(mapStateToProps)(SelectTime);
