import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import { useDispatch } from 'react-redux';
import { func } from 'prop-types';

import { referenceType, valuesType } from 'types/officeTypes';
import OfficeActions from 'redux/office/actions';
import GridWrapper from 'app/screens/Dashboard/screens/Turns/components/GridWrapper';
import Calendar from 'app/screens/Dashboard/screens/Turns/components/Calendar';

import { cardErrorClasses } from '../../constants';
import { handleScrollTo } from '../../utils';

import styles from './styles.module.scss';

const initialDate = new Date();

const SelectDate = ({ onChange, reference, values }) => {
  const dispatch = useDispatch();
  const [currentDay, setCurrentDay] = useState(initialDate);
  const resetCalendar = () => setCurrentDay(initialDate);

  const handleChange = date => {
    if (!currentDay || date.getMonth() === currentDay.getMonth()) {
      onChange(date);
      setCurrentDay(date);
    }
  };

  const handleWorkingDays = date =>
    dispatch(
      OfficeActions.getOfficeWorkingDays(values.selectedOffice, date.getMonth() + 1, date.getFullYear(), () =>
        handleChange(date)
      )
    );

  const handleChangeMonth = date => {
    onChange(null);
    setCurrentDay(date);
    handleWorkingDays(date);
  };

  useEffect(() => {
    resetCalendar();
    return () => onChange(null);
  }, []);

  useEffect(() => {
    handleScrollTo(reference);
    onChange(null);
    resetCalendar();
    handleWorkingDays(initialDate);
  }, [values.selectedOffice]);

  return (
    <GridWrapper
      reference={reference}
      title={i18n.t('Turns:selectDateTitle')}
      className={styles.gridWrapper}
      titleClassName={styles.gridTitle}
    >
      <Calendar
        currentDay={currentDay}
        handleChange={handleChange}
        handleChangeMonth={handleChangeMonth}
        resetCalendar={resetCalendar}
        cardErrorClasses={cardErrorClasses}
        showRefs
        disablePast
        onlyDaysWithTurns
      />
    </GridWrapper>
  );
};

SelectDate.propTypes = {
  onChange: func,
  reference: referenceType,
  values: valuesType
};

export default SelectDate;
