import React, { Fragment } from 'react';
import Fade from 'react-reveal/Fade';
import { bool, func } from 'prop-types';
import { isEmpty } from '@widergy/web-utils/lib/array';
import i18 from 'i18next';
import { UTCard, UTLabel, UTLoading, UTButton } from '@widergy/energy-ui';
import Error from '@material-ui/icons/Error';

import AccountInfo from 'app/components/AccountInfo';
import { rechargeInformationType, rechargeHistoryType } from 'types/accountTypes';

import styles from './styles.module.scss';

const RechargeHistory = ({ handleGoBack, rechargeInformation, loading, recharges, rechargeRenderer }) => {
  const { client_number: clientNumber, prepaidMeterNumber } = rechargeInformation;
  const header = (
    <div>
      <div className={styles.header}>
        <UTLabel bold title>
          {i18.t('RechargeHistory:title')}
        </UTLabel>
        <UTButton text uppercase onPress={handleGoBack}>
          {i18.t('Commons:back')}
        </UTButton>
      </div>
      <hr className={styles.hr} />
    </div>
  );
  return (
    <Fade>
      <div className={styles.container}>
        <AccountInfo
          address={rechargeInformation.address}
          extraInformation={[
            i18.t('RechargeHistory:infoClientNumber', { clientNumber }),
            i18.t('RechargeHistory:infoPrepaidMeterNumber', { prepaidMeterNumber })
          ]}
        />
        <UTCard header={header} className={styles.card}>
          {loading ? (
            <UTLoading loading={loading} className={styles.loading} />
          ) : (
            <Fragment>
              {isEmpty(recharges) ? (
                <div className={styles.containerDefaulText}>
                  <Error className={styles.icon} />
                  <UTLabel xxlarge bold gray className={styles.defaultText}>
                    {i18.t('History:historyEmpty')}
                  </UTLabel>
                </div>
              ) : (
                recharges.map(rechargeRenderer)
              )}
            </Fragment>
          )}
        </UTCard>
      </div>
    </Fade>
  );
};

RechargeHistory.propTypes = {
  handleGoBack: func,
  rechargeInformation: rechargeInformationType,
  loading: bool,
  recharges: rechargeHistoryType,
  rechargeRenderer: func
};

export default RechargeHistory;
