import React from 'react';
import { UTLabel, UTCard } from '@widergy/energy-ui';
import i18 from 'i18next';
import { string, arrayOf } from 'prop-types';

import styles from './styles.module.scss';

const informationRenderer = info => (
  <UTLabel large gray className={styles.infoDetail}>
    {info}
  </UTLabel>
);

const AccountInfo = ({ address, extraInformation }) => (
  <UTCard className={styles.card}>
    <div className={styles.accountInfoContainer}>
      <UTLabel title bold>
        {i18.t('Commons:account')}
      </UTLabel>
      <div className={styles.divider} />
      <div>
        <UTLabel large bold>
          {address}
        </UTLabel>
        <div className={styles.detailContainer}>{extraInformation.map(informationRenderer)}</div>
      </div>
    </div>
  </UTCard>
);

AccountInfo.propTypes = {
  address: string,
  extraInformation: arrayOf(string)
};

export default AccountInfo;
