import i18 from 'i18next';
import {
  cuitFormatter,
  noInitialZero,
  normalizeAll,
  normalizeLowerCase,
  normalizeName,
  normalizeUpperCase,
  numberMaxLength,
  onlyNumbers,
  removeWhitespaces,
  withoutPaste
} from '@widergy/web-utils/lib/normalize';
import * as FORMS from '@widergy/utilitygo-forms-web/dist/constants/fieldTypes';
import { VALIDATE_UTILS } from '@widergy/web-utils';

import appConfig from 'config/appConfig';

const {
  clientNumberNormalizer,
  clientNumberPatternValidation,
  docTypes,
  lastNameMaxLength: LAST_NAME_MAX_LENGTH,
  nameMaxLength: NAME_MAX_LENGTH
} = appConfig.forms;

const { dni, cuit, provincialCi, identityCard, enlistmentBook, passport, civicNotebook } = docTypes;

export const CUIT = 'cuit';

export const accountNumberField = {
  id: 1,
  type: FORMS.FIELD_TEXT,
  key: 'account_number',
  name: 'account_number',
  title: i18.t('AccountSearch:fieldTitle'),
  placeholder: i18.t('AccountSearch:fieldLabel'),
  validate: [
    VALIDATE_UTILS.required(i18.t('Validations:required')),
    VALIDATE_UTILS.pattern(clientNumberPatternValidation, i18.t('Validations:NIS'))
  ],
  normalize: clientNumberNormalizer
};

export const clientNumberField = {
  ...accountNumberField,
  key: 'client_number',
  name: 'client_number'
};

export const cuitField = {
  id: 13,
  type: FORMS.FIELD_TEXT,
  key: 'cuit_number',
  title: i18.t('Forms:cuit'),
  validate: [
    VALIDATE_UTILS.required(i18.t('Validations:required')),
    VALIDATE_UTILS.cuitFormat(i18.t('Validations:CUIT'))
  ],
  normalize: normalizeAll([numberMaxLength(11), cuitFormatter])
};

export const prepaidMeterNumber = {
  id: 6,
  type: FORMS.FIELD_TEXT,
  key: 'prepaid_meter_number',
  name: 'prepaid_meter_number',
  title: i18.t('PrepaidMeterSearch:fieldTitle'),
  placeholder: i18.t('PrepaidMeterSearch:fieldLabel'),
  validate: [
    VALIDATE_UTILS.required(i18.t('Validations:required')),
    VALIDATE_UTILS.minLength(11, i18.t('Validations:prepaidMeterMinLength'))
  ],
  normalize: numberMaxLength(11)
};

export const emailField = {
  id: 2,
  type: FORMS.FIELD_EMAIL,
  key: 'email',
  title: i18.t('Commons:email'),
  required: true,
  validate: [
    VALIDATE_UTILS.required(i18.t('Validations:emailRequired')),
    VALIDATE_UTILS.email(i18.t('Validations:invalidEmail'))
  ],
  normalize: normalizeAll([normalizeLowerCase, removeWhitespaces])
};

export const forcedEmailField = {
  ...emailField,
  autofocus: true,
  normalize: normalizeAll([withoutPaste, normalizeLowerCase, removeWhitespaces])
};

export const emailConfirmationField = {
  id: 3,
  key: 'emailConfirmation',
  type: FORMS.FIELD_EMAIL,
  title: i18.t('Forms:emailConfirmation'),
  validate: [
    VALIDATE_UTILS.required(i18.t('Validations:emailRequired')),
    VALIDATE_UTILS.email(i18.t('Validations:invalidEmail')),
    VALIDATE_UTILS.sameValue('email', i18.t('Validations:emailConfirmationFailure'))
  ],
  normalize: normalizeAll([withoutPaste, normalizeLowerCase, removeWhitespaces])
};

export const labelField = {
  id: 4,
  type: FORMS.FIELD_LABEL,
  key: 'label'
};

export const passwordField = {
  id: 9,
  type: FORMS.FIELD_PASSWORD,
  key: 'password',
  title: i18.t('Commons:password'),
  validate: [VALIDATE_UTILS.required(i18.t('Validations:passwordRequired'))]
};

export const docTypeOptions = [
  ...(dni
    ? [
        {
          id: 0,
          key: 'dni',
          value: 'dni',
          name: i18.t('Forms:dni'),
          placeholder: i18.t('Forms:dni'),
          order: 0
        }
      ]
    : []),
  ...(cuit
    ? [
        {
          id: 1,
          key: 'cuit',
          value: 'cuit',
          name: i18.t('Forms:cuit'),
          placeholder: i18.t('Forms:cuit'),
          order: 1
        }
      ]
    : []),
  ...(provincialCi
    ? [
        {
          id: 2,
          key: 'provincial_ci',
          value: 'provincial_ci',
          name: i18.t('Forms:cip'),
          placeholder: i18.t('Forms:cip'),
          order: 2
        }
      ]
    : []),
  ...(identityCard
    ? [
        {
          id: 3,
          key: 'identity_card',
          value: 'identity_card',
          name: i18.t('Forms:cdi'),
          placeholder: i18.t('Forms:cdi'),
          order: 3
        }
      ]
    : []),
  ...(enlistmentBook
    ? [
        {
          id: 4,
          key: 'enlistment_book',
          value: 'enlistment_book',
          name: i18.t('Forms:le'),
          placeholder: i18.t('Forms:le'),
          order: 4
        }
      ]
    : []),
  ...(passport
    ? [
        {
          id: 6,
          key: 'passport',
          value: 'passport',
          name: i18.t('Forms:passport'),
          placeholder: i18.t('Forms:passport'),
          order: 6
        }
      ]
    : []),
  ...(civicNotebook
    ? [
        {
          id: 7,
          key: 'civic_notebook',
          value: 'civic_notebook',
          name: i18.t('Forms:lc'),
          placeholder: i18.t('Forms:lc'),
          order: 7
        }
      ]
    : [])
];

export const documentTypeField = {
  id: 4,
  type: FORMS.FIELD_SELECT,
  required: true,
  key: 'document_type',
  placeholder: i18.t('Forms:documentTypePlaceholder'),
  field_options: docTypeOptions,
  validate: [VALIDATE_UTILS.required(i18.t('Validations:required'))]
};

export const documentNumberField = {
  id: 2,
  type: FORMS.FIELD_TEXT,
  key: 'document_number',
  required: true,
  placeholder: i18.t('Forms:documentNumberPlaceholder'),
  validate: [VALIDATE_UTILS.required(i18.t('Validations:required')), VALIDATE_UTILS.documentValidation()],
  normalize: normalizeAll([numberMaxLength(11), noInitialZero])
};

export const SOCIALNETWORKS = 'social_networks';
export const CALLCENTER = 'callcenter';
export const VIDEOCONFERENCE = 'video_conference';
export const PERSONAL = 'personal';
export const EMAIL = 'email';
export const VIRTUALOFFICE = 'virtual_office';
export const WEBPAGE = 'web_page';
export const INTERNAL = 'internal';
export const TELEPHONIC = 'telephonic';

export const contactSourceField = {
  id: 5,
  type: FORMS.FIELD_SELECT,
  key: 'contact_source',
  title: i18.t('Forms:contactSource'),
  field_options: [
    { id: '1', value: PERSONAL, name: i18.t(`ContactSources:${PERSONAL}`), order: '1' },
    { id: '2', value: CALLCENTER, name: i18.t(`ContactSources:${CALLCENTER}`), order: '2' },
    { id: '3', value: VIDEOCONFERENCE, name: i18.t(`ContactSources:${VIDEOCONFERENCE}`), order: '3' },
    { id: '4', value: SOCIALNETWORKS, name: i18.t(`ContactSources:${SOCIALNETWORKS}`), order: '4' },
    { id: '5', value: EMAIL, name: i18.t(`ContactSources:${EMAIL}`), order: '5' },
    { id: '6', value: VIRTUALOFFICE, name: i18.t(`ContactSources:${VIRTUALOFFICE}`), order: '6' },
    { id: '7', value: INTERNAL, name: i18.t(`ContactSources:${INTERNAL}`), order: '7' },
    { id: '8', value: WEBPAGE, name: i18.t(`ContactSources:${WEBPAGE}`), order: '8' },
    { id: '9', value: TELEPHONIC, name: i18.t(`ContactSources:${TELEPHONIC}`), order: '9' }
  ],
  validate: [VALIDATE_UTILS.required(i18.t('Validations:required'))]
};

export const nameField = {
  id: 1,
  type: FORMS.FIELD_TEXT,
  key: 'first_name',
  name: 'first_name',
  title: i18.t('Forms:nameTitle'),
  validate: [
    VALIDATE_UTILS.lettersAndSpaces(i18.t('Validations:invalidFormat')),
    VALIDATE_UTILS.maxLength(NAME_MAX_LENGTH, i18.t('Validations:nameMaxLength'))
  ],
  normalize: normalizeAll([normalizeName, normalizeUpperCase])
};

export const lastNameField = {
  id: 2,
  type: FORMS.FIELD_TEXT,
  key: 'last_name',
  name: 'last_name',
  title: i18.t('Forms:lastNameTitle'),
  validate: [
    VALIDATE_UTILS.lettersAndSpaces(i18.t('Validations:invalidFormat')),
    VALIDATE_UTILS.maxLength(LAST_NAME_MAX_LENGTH, i18.t('Validations:lastNameMaxLength'))
  ],
  normalize: normalizeAll([normalizeName, normalizeUpperCase])
};

const { phoneLength } = appConfig.forms;

export const phoneField = {
  id: 10,
  type: FORMS.FIELD_PHONE,
  key: 'phone',
  title: i18.t('Forms:phoneTitle'),
  placeholder: i18.t('PhoneInput:phonePlaceholder'),
  validate: [
    VALIDATE_UTILS.required(i18.t('Validations:required')),
    VALIDATE_UTILS.lengthEquals(phoneLength, i18.t('Validations:invalidFormat'))
  ],
  configuration: {
    area_code_label: i18.t('PhoneInput:areaCodeLabel'),
    area_code_placeholder: i18.t('PhoneInput:areaCodePlaceholder')
  }
};

export const phoneTextField = {
  id: 11,
  type: FORMS.FIELD_TEXT,
  key: 'phone',
  title: i18.t('Forms:phoneTitle'),
  validate: [
    VALIDATE_UTILS.required(i18.t('Validations:required')),
    VALIDATE_UTILS.lengthEquals(phoneLength, i18.t('Validations:invalidFormat'))
  ],
  normalize: normalizeAll([numberMaxLength(phoneLength), onlyNumbers])
};
