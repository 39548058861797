import i18 from 'i18next';
import { ERROR_CODES } from '@widergy/web-utils';

export const getMessage = error => {
  switch (error) {
    case ERROR_CODES.NETWORK_ERROR:
      return i18.t('Maintenance:networkError');
    default:
      return i18.t('Maintenance:maintenanceMessage');
  }
};
