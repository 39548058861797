import React, { PureComponent } from 'react';
import { bool, func } from 'prop-types';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Cancel from '@material-ui/icons/Cancel';
import moment from 'moment';
import i18 from 'i18next';
import Fade from 'react-reveal/Fade';
import { UTLabel, UTCard, UTButton } from '@widergy/energy-ui';

import numeral from 'config/numeral';
import { rechargeHistoryDetailType } from 'types/accountTypes';

import RechargeItemDetail from '../RechargeItemDetail';

import styles from './styles.module.scss';

const APPROVED = 'Aprobado';

class RechargeItem extends PureComponent {
  state = { rechargeOpen: false };

  handleClick = () => this.setState(prevState => ({ rechargeOpen: !prevState.rechargeOpen }));

  render() {
    const { recharge, loading } = this.props;
    const handleOpenTicket = () => this.props.onTicketDialogOpen(recharge.ticket);

    const Header = (
      <div className={styles.header}>
        <div className={styles.title}>
          <UTLabel semibold large secondary>
            {recharge.address ? recharge.address : '-'}
          </UTLabel>
          <UTLabel gray>
            {loading ? recharge.datetime : moment(recharge.datetime).format('DD/MM/YYYY')}
          </UTLabel>
        </div>
        <div className={styles.status}>
          {recharge.status &&
            (recharge.status === APPROVED ? (
              <CheckCircle className={styles.checkCircleIcon} />
            ) : (
              <Cancel className={styles.cancelIcon} />
            ))}
          {recharge.status && (
            <UTLabel semibold secondary>
              {recharge.status}
            </UTLabel>
          )}
        </div>
      </div>
    );
    return (
      <Fade>
        <UTCard
          horizontal
          isOpen={this.state.rechargeOpen}
          onClick={this.handleClick}
          isCollapsible={!loading}
          className={styles.card}
          header={Header}
        >
          {!loading && (
            <div className={styles.content}>
              <RechargeItemDetail
                name={i18.t('RechargeHistory:paymentCode')}
                value={recharge.transaction_code}
              />
              <RechargeItemDetail
                name={i18.t('RechargeHistory:paymentMethod')}
                value={recharge.payment_method}
              />
              {(recharge.amount && recharge.amount) > 0 && (
                <RechargeItemDetail
                  name={i18.t('RechargeHistory:amount')}
                  value={numeral(recharge.amount).format('$0,0.00')}
                />
              )}
              {recharge.performed_by && (
                <RechargeItemDetail name={i18.t('RechargeHistory:doneBy')} value={recharge.performed_by} />
              )}
              <RechargeItemDetail name={i18.t('RechargeHistory:token')} value={recharge.token} />
              {recharge.energy_amount && (
                <RechargeItemDetail
                  name={i18.t('RechargeHistory:energyAmount')}
                  value={recharge.energy_amount}
                />
              )}
              <UTButton
                onPress={handleOpenTicket}
                text
                uppercase
                className={styles.button}
                disabled={!recharge.ticket}
              >
                {i18.t('RechargeHistory:seeTicket')}
              </UTButton>
            </div>
          )}
        </UTCard>
      </Fade>
    );
  }
}

RechargeItem.propTypes = {
  recharge: rechargeHistoryDetailType,
  loading: bool,
  onTicketDialogOpen: func
};

export default RechargeItem;
