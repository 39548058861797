import React, { memo, Fragment } from 'react';
import { arrayOf, any, shape } from 'prop-types';

import ResponsiveRow from './components/ResponsiveRow';

const MobileTable = ({ rows, columns }) => (
  <Fragment>
    {rows.map(row => (
      <ResponsiveRow columns={columns} row={row} />
    ))}
  </Fragment>
);

MobileTable.propTypes = {
  rows: arrayOf(shape(any)),
  columns: arrayOf(any)
};

export default memo(MobileTable);
