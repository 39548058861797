import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import { arrayOf, string, shape, func } from 'prop-types';
import { UTTabs } from '@widergy/energy-ui';

import { ROUTES } from 'constants/routes';

import AccountDigitalBill from './screens/AccountDigitalBill';
import EmergencyRecharge from './screens/EmergencyRecharge';
import styles from './styles.module.scss';

const Home = ({ tabOptions, selectedTab, onTabChange }) => (
  <Fragment>
    <div className={styles.TabsContainer}>
      <UTTabs
        value={selectedTab}
        options={tabOptions}
        onChange={onTabChange}
        tabsProps={{ variant: 'standard', orientation: 'horizontal' }}
        classes={{
          tabsRoot: styles.tabsRoot,
          tabRoot: styles.tabRoot,
          indicator: styles.indicator,
          label: styles.tabLabel
        }}
      />
    </div>
    <Switch>
      <Route exact path={ROUTES.DIGITAL_BILL} component={AccountDigitalBill} />
      <Route exact path={ROUTES.EMERGENCY_RECHARGE} component={EmergencyRecharge} />
    </Switch>
  </Fragment>
);

Home.propTypes = {
  tabOptions: arrayOf(shape({ value: string, label: string })),
  selectedTab: string,
  onTabChange: func
};

export default Home;
