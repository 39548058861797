import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { string, shape } from 'prop-types';
import { objectIsEmpty } from '@widergy/web-utils/lib/object';

import AccountSearch from '../../components/AccountSearch';

import DigitalBill from './components/DigitalBill';

const Home = ({ accountEmail }) => (
  <Fragment>
    <AccountSearch />
    {!objectIsEmpty(accountEmail) && <DigitalBill />}
  </Fragment>
);

Home.propTypes = {
  accountEmail: shape({
    email: string,
    message: string
  })
};

const mapStateToProps = store => ({
  accountEmail: store.account.accountEmail
});

export default connect(mapStateToProps)(Home);
