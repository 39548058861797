import { FIELD_TEXT } from '@widergy/utilitygo-forms-web/dist/constants/fieldTypes';
import { VALIDATE_UTILS } from '@widergy/web-utils';
import i18 from 'i18next';

import { ROUTES } from 'constants/routes';
import { accountNumberField, prepaidMeterNumber } from 'constants/fields';

export const componentOptions = {
  [ROUTES.DIGITAL_BILL]: {
    title: i18.t('AccountSearch:title'),
    subtitle: i18.t('AccountSearch:label'),
    field: {
      ...accountNumberField,
      validate: [...accountNumberField.validate, VALIDATE_UTILS.required(i18.t('Validations:required'))]
    }
  },
  [ROUTES.EMERGENCY_RECHARGE]: {
    title: i18.t('PrepaidMeterSearch:title'),
    subtitle: i18.t('PrepaidMeterSearch:label'),
    field: prepaidMeterNumber
  }
};

export const FIELD_TYPE_PROPS = { [FIELD_TEXT]: { autofocus: true } };
