import React from 'react';
import { handleKeyDown } from '@widergy/utilitygo-forms-web';
import { reduxForm } from 'redux-form';
import { func, node } from 'prop-types';

import { DIGITAL_BILL } from 'constants/reduxForms';

const FormDigitalBill = ({ handleSubmit, children }) => (
  <form onSubmit={handleSubmit} onKeyDown={handleKeyDown(handleSubmit)}>
    {children}
  </form>
);

FormDigitalBill.propTypes = {
  handleSubmit: func,
  children: node
};

export default reduxForm({ form: DIGITAL_BILL })(FormDigitalBill);
