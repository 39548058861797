import React, { memo } from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import { UTTouchableWithoutFeedback, UTLabel } from '@widergy/energy-ui';

import styles from './styles.module.scss';

const Details = ({ detailsList, title }) => (
  <div>
    <UTLabel large white className={styles.title}>
      {title}
    </UTLabel>
    <div className={styles.dataContainer}>
      {detailsList.map(detail => (
        <UTTouchableWithoutFeedback onClick={detail.onClick} className={styles.detailTag}>
          <UTLabel large primary>
            {detail.label}
          </UTLabel>
        </UTTouchableWithoutFeedback>
      ))}
    </div>
  </div>
);

Details.propTypes = {
  detailsList: arrayOf(
    shape({
      label: string,
      onClick: func
    })
  ),
  title: string
};

export default memo(Details);
