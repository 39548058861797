import React, { PureComponent, Fragment } from 'react';
import { UTLabel, UTCard, UTIconButton, AlertHandler, UTTooltip } from '@widergy/energy-ui';
import i18 from 'i18next';
import { getIn } from 'seamless-immutable';
import { connect } from 'react-redux';
import { string, bool, arrayOf, number, shape } from 'prop-types';
import { retrieveSuccessMessage } from '@widergy/web-utils/lib/message';
import { submit, hasSubmitFailed, isInvalid } from 'redux-form';
import { push } from 'connected-react-router';
import History from '@material-ui/icons/History';

import { HISTORY_SEND_BILL } from 'constants/routes';
import { DIGITAL_BILL } from 'constants/reduxForms';

import { ADD, EDIT, REMOVE, configDialog } from './constants';
import styles from './styles.module.scss';
import DigitalBill from './layout';
import DialogDigitalBill from './components/DialogDigitalBill';

class DigitalBillContainer extends PureComponent {
  state = { userAction: null, emailEdit: null };

  handleSubmit = async value => {
    if (this.state.userAction === EDIT && value.email === this.state.emailEdit) {
      AlertHandler.error(i18.t('Validations:sameEmail'));
    } else {
      await this.props.dispatch(
        configDialog()[this.state.userAction].action({
          emails: [value.email],
          contactSource: value.contact_source,
          clientNumber: this.props.clientNumber
        })
      );
      this.setState({ userAction: null, emailEdit: null });
    }
  };

  handleAccept = () => this.props.dispatch(submit(DIGITAL_BILL));

  handleOpenAdd = () => this.setState({ userAction: ADD });

  handleOpenEdit = () => this.setState({ userAction: EDIT, emailEdit: this.props.accountEmails[0] });

  handleOpenRemove = () => this.setState({ userAction: REMOVE });

  handleCloseDialog = () => this.setState({ userAction: null, emailEdit: null });

  handleGoToHistory = () => this.props.dispatch(push(HISTORY_SEND_BILL));

  render() {
    const { accountEmails, loading, clientNumber, invalid } = this.props;
    const { userAction, emailEdit } = this.state;
    return (
      <UTCard className={styles.card}>
        <div className={styles.containerTitle}>
          <UTLabel large bold>
            {i18.t('DigitalBill:title')}
          </UTLabel>
          <UTTooltip interactive={false} content={i18.t('Commons:history')}>
            <span>
              <UTIconButton onClick={this.handleGoToHistory}>
                <History />
              </UTIconButton>
            </span>
          </UTTooltip>
        </div>
        <Fragment>
          <hr className={styles.hr} />
          <DigitalBill
            email={accountEmails[0]}
            openEdit={this.handleOpenEdit}
            openRemove={this.handleOpenRemove}
            openAdd={this.handleOpenAdd}
            message={retrieveSuccessMessage(accountEmails, i18.t('DigitalBill:withoutEmail'))}
          />
        </Fragment>
        <DialogDigitalBill
          isOpen={!!userAction}
          onClose={this.handleCloseDialog}
          invalid={invalid}
          userAction={userAction}
          handleSubmit={this.handleSubmit}
          handleAccept={this.handleAccept}
          email={emailEdit}
          loading={loading}
          clientNumber={userAction === REMOVE && clientNumber}
        />
      </UTCard>
    );
  }
}

DigitalBillContainer.propTypes = {
  accountEmails: shape({
    email: arrayOf(string),
    message: string
  }),
  loading: bool,
  invalid: bool,
  clientNumber: number
};

const mapStateToProps = store => ({
  clientNumber: store.account.accountNumber,
  accountEmails: getIn(store, ['account', 'accountEmail', 'contact_emails']),
  loading:
    store.account.addEmailLoading || store.account.editEmailLoading || store.account.removeEmailLoading,
  invalid: hasSubmitFailed(DIGITAL_BILL)(store) && isInvalid(DIGITAL_BILL)(store)
});

export default connect(mapStateToProps)(DigitalBillContainer);
