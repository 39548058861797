import api from 'config/api';

import * as LocalStorageService from './LocalStorageService';

const AUTH_HEADER = 'Authorization';

export const saveSession = sessionToken => {
  api.setHeader(AUTH_HEADER, sessionToken);
  LocalStorageService.setSessionToken(sessionToken);
};

export default {
  authInit: () => {
    const currentSessionToken = LocalStorageService.getSessionToken();
    if (currentSessionToken) {
      api.setHeader(AUTH_HEADER, currentSessionToken);
      return true;
    }
    return false;
  },
  signIn: async user => api.post('admin_api/v1/sessions', user, { timeout: 60000 }),
  signOut: () => {
    LocalStorageService.removeSessionToken();
    api.setHeader(AUTH_HEADER);
  }
};
