import React from 'react';
import { arrayOf, func, string, shape } from 'prop-types';

import OptionSelector from 'app/components/OptionSelector';

const CancelReasonSelector = ({ getSelectedOption, cancelReasons }) => (
  <OptionSelector getSelectedOption={getSelectedOption} options={cancelReasons} />
);

CancelReasonSelector.propTypes = {
  getSelectedOption: func,
  cancelReasons: arrayOf(shape({ key: string, label: string }))
};

export default CancelReasonSelector;
