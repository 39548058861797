import i18 from 'i18next';
import { VALIDATE_UTILS } from '@widergy/web-utils';

import appConfig from 'config/appConfig';
import {
  nameField,
  lastNameField,
  documentTypeField,
  documentNumberField,
  emailField,
  clientNumberField,
  phoneField,
  phoneTextField,
  cuitField,
  CUIT
} from 'constants/fields';

const config = appConfig.contactInformation;
const { phoneFieldWithAreaCode, clientNumberPatternValidation, clientNumberNormalizer } = appConfig.forms;
const { clientNumberRequired } = appConfig.turns;

const phoneFieldDefinition = phoneFieldWithAreaCode ? phoneField : phoneTextField;

export const getFields = documentType => [
  {
    ...nameField,
    id: 1,
    order: 1,
    suborder: 1,
    required: true
  },
  {
    ...lastNameField,
    id: 2,
    order: 1,
    suborder: 2,
    required: true
  },
  {
    ...documentTypeField,
    id: 3,
    order: 1,
    suborder: 3,
    title: i18.t('Forms:documentTypePlaceholder'),
    placeholder: null
  },
  {
    ...(documentType === CUIT ? { ...cuitField } : { ...documentNumberField }),
    id: 4,
    order: 1,
    suborder: 4,
    title: i18.t(`Forms:${documentType === CUIT ? 'cuitNumberTitle' : 'documentNumberTitle'}`),
    placeholder: null
  },
  {
    ...phoneFieldDefinition,
    id: 5,
    order: 2,
    suborder: 1
  },
  {
    ...emailField,
    id: 6,
    order: 2,
    suborder: 2,
    ...(config.emailRequired
      ? {}
      : {
          required: false,
          validate: [VALIDATE_UTILS.email(i18.t('Validations:invalidEmail'))]
        })
  },
  {
    ...clientNumberField,
    id: 7,
    order: 2,
    suborder: 3,
    title: i18.t('Forms:clientNumberTitle'),
    placeholder: null,
    validate: [
      ...(clientNumberRequired ? [VALIDATE_UTILS.required(i18.t('Validations:required'))] : []),
      VALIDATE_UTILS.pattern(clientNumberPatternValidation, i18.t('Validations:NIS'))
    ],
    normalize: clientNumberNormalizer
  }
];
