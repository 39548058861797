import i18 from 'i18next';
import moment from 'moment';

export const formatInformation = data =>
  [
    {
      show: !!data.client_number,
      value: data.client_number,
      label: i18.t('RechargeInformation:clientNumber')
    },
    {
      show: !!data.holder_name,
      value: data.holder_name,
      label: i18.t('RechargeInformation:holderName')
    },
    {
      show: !!data.address,
      value: data.address,
      label: i18.t('RechargeInformation:address')
    },
    {
      show: !!data.energy_amount,
      value: `${data.energy_amount}kWh`,
      label: i18.t('RechargeInformation:energyAmount')
    },
    {
      show: !!data.accumulated_energy,
      value: `${data.accumulated_energy}kWh`,
      label: i18.t('RechargeInformation:energyAccumulated')
    },
    {
      show: !data.available && !!data.next_available_date,
      value: moment(data.next_available_date).format('DD/MM/YYYY HH:ss:mm'),
      label: i18.t('RechargeInformation:nextDate')
    }
  ].filter(item => item.show);
