import { createSelector } from 'reselect';

import styles from './styles.module.scss';

const classNameHistoryAdherence = {
  A: styles.add,
  B: styles.delete,
  M: styles.modify
};

const classNamehistorySendBills = {
  A: styles.add,
  P: styles.modify,
  X: styles.delete,
  E: styles.modify,
  O: styles.modify,
  D: styles.delete,
  R: styles.delete,
  L: styles.add
};

export const historyAdherenceSelector = createSelector(
  store => store.history.historyAdherence,
  historyAdherence =>
    historyAdherence.map(item => ({
      ...item,
      actionClassName: classNameHistoryAdherence[item.type]
    }))
);

export const historySendBillsSelector = createSelector(
  store => store.history.historySendBills,
  historySendBills =>
    historySendBills.map(item => ({
      ...item,
      statusClassName: classNamehistorySendBills[item.type]
    }))
);
