import React from 'react';
import { connect } from 'react-redux';
import { any, string } from 'prop-types';
import i18 from 'i18next';
import { UTTouchableWithoutFeedback, UTLabel } from '@widergy/energy-ui';
import Logout from '@material-ui/icons/ExitToApp';

import logo from 'app/assets/topbarLogo.png';
import logOutIcon from 'app/assets/logOutIcon.png';
import AuthActions from 'redux/auth/actions';
import appConfig from 'config/appConfig';

import styles from './styles.module.scss';

const UTTopbar = ({ dispatch, currentUser, className }) => {
  const handleLogout = () => dispatch(AuthActions.signOut());
  return (
    <div className={`${styles.topbar} ${className}`}>
      {appConfig.topbar.withLogo && (
        <div className={styles.topbarLogo}>
          <img src={logo} alt="" className={styles.logo} />
          {appConfig.topbar.withLabel && (
            <UTLabel xlarge white bold>
              {i18.t('Commons:topbar')}
            </UTLabel>
          )}
        </div>
      )}
      {appConfig.topbar.withLogout && currentUser && (
        <UTTouchableWithoutFeedback onClick={handleLogout} className={styles.rightSection}>
          <UTLabel xlarge white bold className={styles.signout}>
            {i18.t('Commons:signOut')}
          </UTLabel>
          {appConfig.topbar.withLogOutCustomIcon ? (
            <img src={logOutIcon} alt="" className={styles.logOutIcon} />
          ) : (
            <Logout className={styles.icon} />
          )}
        </UTTouchableWithoutFeedback>
      )}
    </div>
  );
};

UTTopbar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  currentUser: any,
  className: string
};

const mapStateToProps = store => ({
  currentUser: store.user.currentUser
});

export default connect(mapStateToProps)(UTTopbar);
