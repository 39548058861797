import React, { memo } from 'react';
import { arrayOf, any } from 'prop-types';
import { UTLabel } from '@widergy/energy-ui';

import styles from './styles.module.scss';

const ResponsiveRow = ({ row = {}, columns = [] }) => (
  <div className={styles.container}>
    {columns.map(column => (
      <div className={`${styles.baseRow} ${row[`${column.key}ClassName`]}`}>
        <UTLabel semibold darkGray className={styles.title}>
          {column.label || ''}
        </UTLabel>
        <UTLabel semibold darkGray className={styles.contentLabel}>
          {row[column.key] || '-'}
        </UTLabel>
      </div>
    ))}
  </div>
);

ResponsiveRow.propTypes = {
  row: arrayOf(any),
  columns: arrayOf(any)
};

export default memo(ResponsiveRow);
