import React, { Fragment } from 'react';
import i18 from 'i18next';
import { func, number, instanceOf, bool, string } from 'prop-types';
import { UTLabel, UTLoading } from '@widergy/energy-ui';

import { officesType } from 'types/officeTypes';
import GridSelector from 'app/screens/Dashboard/components/GridSelector';

import GridWrapper from '../../components/GridWrapper';
import Calendar from '../../components/Calendar';

import TurnsList from './components/TurnsList';
import SearchTurns from './components/SearchTurns';
import styles from './styles.module.scss';
import { getOfficeOptions } from './utils';

const ManageTurns = ({
  selectedOffice,
  changeOffice,
  currentDay,
  handleCalendarChange,
  handleChangeMonth,
  onSearchTurnsSubmit,
  searchDialogIsOpen,
  onCloseSearchDialog,
  offices,
  resetCalendar,
  hideCalendar,
  hideTurns,
  onSelectFilter,
  filterValue,
  loading
}) => (
  <UTLoading loading={loading}>
    <div className={styles.mainContent}>
      {selectedOffice ? (
        <Fragment>
          <div className={styles.leftColumn}>
            <GridSelector
              classes={{
                optionsContainer: styles.gridContainer,
                gridWrapperContainer: styles.container,
                scrollbar: styles.scrollbar,
                titleClassName: styles.gridTitle,
                optionCardsClasses: { root: styles.gridCard }
              }}
              currentValue={selectedOffice}
              emptyMessage={i18.t('Turns:searchOfficesZeroResults')}
              handleSetValue={changeOffice}
              loading={loading}
              options={getOfficeOptions(offices)}
              searchPlaceholder={i18.t('Turns:searchOfficesPlaceholder')}
              gridSizeMD={6}
              gridSizeXS={12}
              title={i18.t('Turns:selectOfficeTitle')}
              scrollable
            />
            {!hideCalendar && (
              <GridWrapper title={i18.t('Turns:calendarTitle')} className={styles.calendarContainer}>
                <Calendar
                  currentDay={currentDay}
                  handleChange={handleCalendarChange}
                  handleChangeMonth={handleChangeMonth}
                  resetCalendar={resetCalendar}
                />
              </GridWrapper>
            )}
          </div>
          <div className={styles.rightColumn}>
            <UTLabel bold large className={styles.turnsListTitle}>
              {i18.t('Turns:turnsListTitle')}
            </UTLabel>
            <SearchTurns
              searchDialogIsOpen={searchDialogIsOpen}
              onSubmit={onSearchTurnsSubmit}
              onCloseSearchDialog={onCloseSearchDialog}
              onSelectFilter={onSelectFilter}
            />
            {!hideTurns && <TurnsList filterValue={filterValue} />}
          </div>
        </Fragment>
      ) : (
        <UTLabel bold xxlarge>
          {i18.t('Turns:emptyOffices')}
        </UTLabel>
      )}
    </div>
  </UTLoading>
);

ManageTurns.propTypes = {
  selectedOffice: number,
  changeOffice: func,
  currentDay: instanceOf(Date),
  handleCalendarChange: func,
  handleChangeMonth: func,
  offices: officesType,
  onSearchTurnsSubmit: func,
  onCloseSearchDialog: func,
  onSelectFilter: func,
  filterValue: string,
  searchDialogIsOpen: bool,
  resetCalendar: func,
  hideCalendar: bool,
  hideTurns: bool,
  loading: bool
};

export default ManageTurns;
