import i18 from 'i18next';

import { ROUTES } from 'constants/routes';

export const handleTabOptions = (backofficeVisibleTabs = {}) =>
  [
    {
      value: ROUTES.DIGITAL_BILL,
      label: i18.t('Home:digitalBill'),
      show: backofficeVisibleTabs.show_digital_bill
    },
    {
      value: ROUTES.EMERGENCY_RECHARGE,
      label: i18.t('Home:emergencyRecharge'),
      show: backofficeVisibleTabs.show_recharge_sos
    }
  ].filter(item => item.show);
