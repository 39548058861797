import i18 from 'i18next';

import AccountActions from 'redux/account/actions';
import { forcedEmailField, emailConfirmationField, labelField, contactSourceField } from 'constants/fields';

export const ADD = 'add';
export const EDIT = 'edit';
export const REMOVE = 'remove';

export const configDialog = accountNumber => ({
  [ADD]: {
    title: i18.t('DigitalBill:addTitle'),
    fields: [forcedEmailField, emailConfirmationField, contactSourceField],
    action: AccountActions.addEmail
  },
  [EDIT]: {
    title: i18.t('DigitalBill:editTitle'),
    fields: [forcedEmailField, emailConfirmationField, contactSourceField],
    action: AccountActions.editEmail
  },
  [REMOVE]: {
    title: i18.t('DigitalBill:removeTitle'),
    fields: [
      {
        ...labelField,
        title: i18.t('DigitalBill:removeField', { accountNumber })
      },
      contactSourceField
    ],
    action: AccountActions.removeEmail
  }
});
