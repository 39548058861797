import React, { Component } from 'react';
import { Form, mergeFieldProps } from '@widergy/utilitygo-forms-web';
import { Field } from 'redux-form';
import { any, shape, string, arrayOf, bool } from 'prop-types';

import { DefaultMessages, Components, fieldTypeProps } from './constants';

class UTForm extends Component {
  fieldProps = mergeFieldProps(this.props.fieldTypeProps, fieldTypeProps);

  render() {
    const { form, fields, fieldValues, formClasses, fieldClasses, disableAllFields } = this.props;
    return (
      <Form
        form={form}
        fields={fields}
        fieldValues={fieldValues}
        components={Components}
        defaultMessages={DefaultMessages}
        formClasses={formClasses}
        fieldClasses={fieldClasses}
        FieldComponent={Field}
        fieldTypeProps={this.fieldProps}
        disableAllFields={disableAllFields}
      />
    );
  }
}

UTForm.propTypes = {
  form: any, // eslint-disable-line react/forbid-prop-types
  fields: any, // eslint-disable-line react/forbid-prop-types
  fieldTypeProps: any, // eslint-disable-line react/forbid-prop-types
  fieldValues: arrayOf(shape({ key: string })),
  formClasses: shape({ formContainer: string, formTitle: string, fieldRowContainer: string }),
  fieldClasses: shape({ container: string, comment: string, error: string }),
  disableAllFields: bool
};

export default UTForm;
