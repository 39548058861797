import Immutable from 'seamless-immutable';
import { completeReducer, createReducer } from 'redux-recompose';

import { actions } from './actions';

export const defaultState = {
  healthCheck: {},
  configuration: {}
};

const reducerDescription = {
  primaryActions: [actions.HEALTH_CHECK, actions.GET_CONFIG],
  override: {
    [actions.HEALTH_CHECK_FAILURE]: (state, action) =>
      Immutable.merge(state, {
        healthCheck: { healthy: false, message: action.payload },
        healthCheckLoading: false
      }),
    [actions.UTILITY_HEALTH_CHECK_SUCCESS]: state =>
      Immutable.merge(state, {
        healthCheck: { healthy: true },
        healthCheckLoading: false
      }),
    [actions.UTILITY_HEALTH_CHECK_FAILURE]: (state, action) =>
      Immutable.merge(state, {
        healthCheck: { healthy: false, message: action.payload },
        healthCheckLoading: false
      })
  }
};

export const reducer = createReducer(Immutable(defaultState), completeReducer(reducerDescription));
