import claimAlertIcon from 'app/assets/alertDialog/alert-dialog-claim-icon.png';
import errorAlertIcon from 'app/assets/alertDialog/alert-dialog-error-icon.png';
import infoAlertIcon from 'app/assets/alertDialog/alert-dialog-info-icon.png';
import procedureAlertIcon from 'app/assets/alertDialog/alert-dialog-procedure-icon.png';
import paymentAlertIcon from 'app/assets/alertDialog/alert-dialog-payment-icon.png';

const PROCEDURE_TYPE = 'procedure';
const CLAIM_TYPE = 'claim';
const PAYMENT_TYPE = 'payment';
export const ERROR = 'error';
export const DIGITAL_BILL = 'digitalBill';
export const INFO = 'info';

export const pickIcon = type => {
  switch (type) {
    case PROCEDURE_TYPE:
      return procedureAlertIcon;
    case DIGITAL_BILL:
      return procedureAlertIcon;
    case CLAIM_TYPE:
      return claimAlertIcon;
    case PAYMENT_TYPE:
      return paymentAlertIcon;
    case ERROR:
      return errorAlertIcon;
    case INFO:
      return infoAlertIcon;
    default:
      return null;
  }
};
