import i18 from 'i18next';
import moment from 'moment';

const STATUS_SEND_BILLS = {
  A: i18.t('History:pending'),
  P: i18.t('History:pending'),
  X: i18.t('History:rejected'),
  E: i18.t('History:send'),
  R: i18.t('History:rejected'),
  O: i18.t('History:open'),
  D: i18.t('History:spam'),
  L: i18.t('History:read')
};

const STATUS_ADHERENCE = {
  A: i18.t('History:add'),
  M: i18.t('History:modify'),
  B: i18.t('History:delete')
};

const newObjectHistoryAdherence = item => ({
  prevEmail: item.previous_value,
  email: item.value,
  channel: item.channel ? i18.t(`Channels:${item.channel.toUpperCase()}`) : '-',
  action: STATUS_ADHERENCE[item.action],
  type: item.action,
  date: moment(item.created_at).format('DD/MM/YYYY - HH:mm:ss'),
  username: item.username,
  contactSource: item.contact_source ? i18.t(`ContactSources:${item.contact_source}`) : '-'
});

const newObjectHistorySendBills = item => ({
  billId: item.bill_id,
  sendIntents: item.send_intents,
  email: item.email,
  type: item.status,
  status: STATUS_SEND_BILLS[item.status],
  date: moment(item.sent_at).format('DD/MM/YYYY - HH:mm:ss')
});

export const historySendBillsSelector = history =>
  history
    .sort(
      (a, b) =>
        moment(a.sent_at).format('DD/MM/YYYY - HH:mm:ss') < moment(b.sent_at).format('DD/MM/YYYY - HH:mm:ss')
    )
    .map(newObjectHistorySendBills);

export const historyAdherenceSelector = history =>
  history
    .sort(
      (a, b) =>
        moment(a.created_at).format('DD/MM/YYYY - HH:mm:ss') <
        moment(b.created_at).format('DD/MM/YYYY - HH:mm:ss')
    )
    .map(newObjectHistoryAdherence);
