import appConfig from 'config/appConfig';

export const getAvailableOffices = (offices, procedure) =>
  offices.filter(office => !office.hidden_forms?.some(form => form === procedure));

export const mapOfficeOptions = offices =>
  offices.map(office => ({
    name: office.name,
    description: `${appConfig.offices.officeTag.withOfficeCity ? office.city : ''}${
      appConfig.offices.officeTag.withOfficeCity ? (office.city && office.address ? ' - ' : '') : ''
    }${office.address}`,
    value: office.id
  }));
