import Immutable from 'seamless-immutable';
import { completeReducer, createReducer } from 'redux-recompose';

import { actions } from './actions';

/* ------------- History reducer ------------- */
export const defaultState = {
  historyAdherence: [],
  historySendBills: [],
  address: ''
};
const reducerDescription = {
  primaryActions: [actions.GET_HISTORY_ADHERENCE, actions.GET_HISTORY_SEND_BILLS],
  override: {
    [actions.GET_HISTORY_SEND_BILLS_SUCCESS]: (state, action) =>
      Immutable.merge(state, {
        ...state,
        historySendBills: action.payload.history,
        address: action.payload.address,
        historySendBillsLoading: false
      }),
    [actions.CLEAN_HISTORY_ADHERENCE]: state =>
      Immutable.merge(state, { ...state, historyAdherence: defaultState.historyAdherence }),
    [actions.CLEAN_HISTORY_SEND_BILLS]: state =>
      Immutable.merge(state, { ...state, historySendBills: defaultState.historySendBills })
  }
};

export const reducer = createReducer(Immutable(defaultState), completeReducer(reducerDescription));
