import React, { memo } from 'react';
import i18 from 'i18next';
import { UTLabel } from '@widergy/energy-ui';

import { references } from './constants';
import styles from './styles.module.scss';

const References = () => (
  <div className={styles.referenceContainer}>
    <div>
      <UTLabel>{i18.t('Turns:calendarReferenceTitle')}</UTLabel>
      <div className={styles.tags}>
        {references.map(ref => (
          <div className={styles.referenceTag}>
            <div className={`${styles.referenceCircle} ${ref.className}`}>1</div>
            <UTLabel>{ref.label}</UTLabel>
          </div>
        ))}
      </div>
      <UTLabel>{i18.t('Turns:calendarReferenceNotice')}</UTLabel>
    </div>
    <UTLabel className={styles.reminder}>{i18.t('Turns:calendarReferenceReminder')}</UTLabel>
  </div>
);

export default memo(References);
